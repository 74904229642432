import { css } from "@emotion/css";
import palette from "./palette";

const commonStyles = {
  controlBtn: css`
    max-width: 150px !important;
    min-height: 30px !important;
    max-height: 30px !important;
  `,
  controlBtnMobile: css`
    max-width: 100% !important;
  `,
  filterSelect: css`
    min-width: 100px;

    font-weight: 600 !important;

    &::before,
    &::after {
      border-bottom: none !important;
    }
  `,
  listItemContainer: css`
    min-height: 80px;
  `,
  accordion: css`
    border-bottom: 1px solid ${palette.grey200};
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;

    &.Mui-expanded {
      border-bottom-color: transparent;
    }
    &::before {
      background-color: transparent !important;
    }
  `,
  textBtnMargin: css`
    margin: -6px -8px !important;
  `,
};

export default commonStyles;
