import PrimaryButton from "library/Atoms/Button/PrimaryButton";
import PageTitle from "library/Atoms/Text/PageTitle";
import { first, size } from "lodash";
import useNewOrderForm from "pages/NewOrder/hooks/useNewOrderForm";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { formatDate } from "utility/date-fns";

const ThankYouForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [{ customer, references }] = useNewOrderForm();

  const onGoHome = useCallback(() => navigate("/"), [navigate]);

  return (
    <>
      <div className="mb-3 text-color-primary text-underlined">{t("newOrder.breadcrumbs.thankYouStep")}</div>

      <PageTitle>
        {t("newOrder.forms.thankYouForm.formTitle", { username: `${customer?.firstName} ${customer?.lastName}` })}
      </PageTitle>

      <p className="mt-2">{t("newOrder.forms.thankYouForm.formDescription")}</p>

      <div className="d-flex flex-nowrap mb-2 pb-2">
        <span>{t("newOrder.forms.thankYouForm.referenceNumberLabel")}:</span>
        <span className="pl-4 text-fw-bold text-color-grey900" data-cy="new-order-original-reference">
          {first(references).reference}
        </span>
      </div>

      {size(references) > 1 && (
        <div className="d-flex flex-wrap mb-4 pb-2" data-cy="new-repeated-order-references">
          <span>{t("newOrder.forms.thankYouForm.futureOrdersLabel")}:</span>

          <div className="d-flex flex-column">
            {references.slice(1, size(references)).map(({ reference, date }) => (
              <span key={date} className="pl-4 text-fw-bold text-color-grey900">
                {formatDate(date)} - {reference}
              </span>
            ))}
          </div>
        </div>
      )}

      <div className="d-flex flex-sm-wrap flex-wrap-reverse justify-content-center justify-content-sm-end col-12 mt-3">
        <PrimaryButton
          id="go-home-button"
          className="col-12 col-sm-5 col-md-3"
          label={t("common.buttons.backToBtn", { context: "dashboard" })}
          onClick={onGoHome}
        />
      </div>
    </>
  );
};

export default ThankYouForm;
