import { cx } from "@emotion/css";
import { unwrapResult } from "@reduxjs/toolkit";
import { FormikProvider, useFormik } from "formik";
import useDebounce from "hooks/useDebounce";
import PrimaryButton from "library/Atoms/Button/PrimaryButton";
import PhoneInput from "library/Atoms/Input/PhoneInput";
import TextInput from "library/Atoms/Input/TextInput";
import Select from "library/Atoms/Select";
import Checkbox from "library/Atoms/Switch/Checkbox";
import Tooltip from "library/Atoms/Tooltip";
import { omit } from "lodash";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { retrieveLocalizations } from "store/features/localization.store";
import { createContactRequest } from "store/requests/contact.requests";
import { DEFAULT_CITY } from "../../../../constants";
import styles from "./RegisterForm.styles";
import RegisterFormValidation from "./RegisterForm.validation";

const RegisterForm = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const localizations = useSelector(retrieveLocalizations);

  const registerForm = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      password: "",
      passwordConfirmation: "",
      email: "",
      invoiceEmail: "",
      phone: "",
      company: "",
      companyVat: "",
      companyAddress: "",
      companyBillingAddress: "",
      city: DEFAULT_CITY,
      acceptTerms: false,
    },
    onSubmit: (values, { setSubmitting, setStatus }) => {
      dispatch(
        createContactRequest({ ...omit(values, ["passwordConfirmation", "acceptTerms"]), locale: i18n.language })
      )
        .then(unwrapResult)
        .then(() => {
          setStatus("submitted");
          setSubmitting(false);
        })
        .catch(() => setSubmitting(false));
    },
    validationSchema: RegisterFormValidation,
    validateOnBlur: true,
  });
  const { submitForm, isValid, isSubmitting, dirty: isFormDirty, setFieldValue, status: formStatus } = registerForm;

  const debouncedSetFieldValue = useDebounce(setFieldValue);

  useEffect(() => {
    if (formStatus !== "submitted") return;
    navigate("/login");
  }, [formStatus, navigate]);

  return (
    <FormikProvider value={registerForm}>
      <div className="d-flex flex-wrap">
        <TextInput
          id="firstName"
          name="firstName"
          className="col-xl-6 col-12 pl-xl-0 pr-xl-3 px-0"
          label={t("common.forms.firstNameLabel")}
          onChange={debouncedSetFieldValue}
          required
        />

        <TextInput
          id="lastName"
          name="lastName"
          className="col-xl-6 col-12 pl-xl-3 pr-xl-0 px-0"
          label={t("common.forms.lastNameLabel")}
          onChange={debouncedSetFieldValue}
          required
        />

        <TextInput
          id="email"
          name="email"
          className="col-xl-6 col-12 pl-xl-0 pr-xl-3 px-0"
          label={t("common.forms.emailLabel")}
          onChange={debouncedSetFieldValue}
          required
        />

        <TextInput
          id="invoiceEmail"
          name="invoiceEmail"
          className="col-xl-6 col-12 pl-xl-3 pr-xl-0 px-0"
          label={t("common.forms.invoiceEmailLabel")}
          onChange={debouncedSetFieldValue}
          required
        />

        <TextInput
          id="password"
          name="password"
          className="col-xl-6 col-12 pl-xl-0 pr-xl-3 px-0"
          label={
            <>
              <span>{t("common.forms.passwordLabel")}</span>
              <Tooltip>{t("register.forms.registerForm.passwordHelper")}</Tooltip>
            </>
          }
          onChange={debouncedSetFieldValue}
          type="password"
          required
        />

        <TextInput
          id="passwordConfirmation"
          name="passwordConfirmation"
          className="col-xl-6 col-12 pl-xl-3 pr-xl-0 px-0"
          label={t("register.forms.registerForm.confirmPasswordLabel")}
          onChange={debouncedSetFieldValue}
          type="password"
          required
        />

        <PhoneInput
          id="phone"
          name="phone"
          className="col-xl-6 col-12 pl-xl-0 pr-xl-3 px-0"
          label={t("common.forms.phoneLabel")}
          onChange={debouncedSetFieldValue}
          required
        />

        <TextInput
          id="companyVat"
          name="companyVat"
          className="col-xl-6 col-12 pl-xl-3 pr-xl-0 px-0"
          label={t("common.forms.companyVATLabel")}
          onChange={debouncedSetFieldValue}
        />

        <Select
          id="city"
          name="city"
          className="col-12 mb-4 px-0"
          label={t("common.forms.cityLabel")}
          options={localizations
            .filter(({ disabled }) => !disabled)
            .map(({ city_name }) => ({ value: city_name, label: t(`utils.cities.${city_name}`) }))}
          onChange={setFieldValue}
          required
        />

        <TextInput
          id="company"
          name="company"
          className="col-12 px-0"
          label={t("common.forms.companyNameLabel")}
          onChange={debouncedSetFieldValue}
          required
        />

        <TextInput
          id="companyAddress"
          name="companyAddress"
          className="col-12 px-0"
          label={t("common.forms.companyAddressLabel")}
          onChange={debouncedSetFieldValue}
          required
        />

        <TextInput
          id="companyBillingAddress"
          name="companyBillingAddress"
          className="col-12 px-0"
          label={t("common.forms.companyBillingAddressLabel")}
          onChange={debouncedSetFieldValue}
        />
      </div>

      <div>
        <Checkbox
          id="acceptTerms"
          name="acceptTerms"
          className="mt-4 mb-1"
          label={t("register.forms.registerForm.acceptPolicyLabel")}
          onChange={setFieldValue}
        />
      </div>

      <PrimaryButton
        id="signup-btn"
        className={cx(styles.btn, "col-12 mt-5 px-0 w-100")}
        label={t("register.forms.registerForm.signUpBtn")}
        onClick={submitForm}
        loading={isSubmitting}
        disabled={!isValid || isSubmitting || !isFormDirty}
      />
    </FormikProvider>
  );
};

export default RegisterForm;
