import { cx } from "@emotion/css";
import ErrorIcon from "@mui/icons-material/Error";
import useDeviceType from "hooks/useDeviceType";
import FlatBoxLayout from "library/Layouts/BoxLayout/FlatBoxLayout";
import PropTypes from "prop-types";
import styles from "./MessageLayout.styles";

const MessageLayout = ({ className, labelSection, color, ...restProps }) => {
  const { isMobile } = useDeviceType();

  return (
    <FlatBoxLayout
      className={cx(className, "align-items-center text-color-white", isMobile && "col-12 px-0")}
      color={color}
      {...restProps}
    >
      <ErrorIcon className={cx(styles.icon, "mr-2")} />
      <span className="text-fw-semibold">{labelSection}</span>
    </FlatBoxLayout>
  );
};

MessageLayout.defaultProps = {
  className: "",
  labelSection: null,
  color: "primary",
};

MessageLayout.propTypes = {
  className: PropTypes.string,
  labelSection: PropTypes.node,
  color: PropTypes.oneOf(["primary", "warning", undefined]),
};

export default MessageLayout;
