import { addMinutes, isAfter, isBefore, isWeekend, startOfDay } from "date-fns";
import { last } from "lodash";
import {
  DEFAULT_LOCATION_DATA,
  DEFAULT_REPEAT_ORDER_SETTINGS,
  DELIVERY_TIME_GAP,
  DELIVERY_TIME_GAP_EXPRESS,
} from "../../../constants";
import { formatHours } from "../../../utility/date-fns";
import { isPeakTime } from "../../../utility/helpers/general";
import { generateNewDrop, generateTimePickerData } from "../../../utility/helpers/orders";

export const generatePickUpTimeOptions = ({ startDate, isExpress, withoutPastLimit }) => {
  if (!startDate) return [];

  const options = generateTimePickerData(startDate, { withoutPastLimit }).map((option) => {
    const isPeakTimeOption = withoutPastLimit
      ? isPeakTime(new Date(option.value))
      : !isWeekend(startDate) && isPeakTime(new Date(option.value));
    return isPeakTimeOption ? { ...option, className: "text-color-warning" } : option;
  });

  if (!isExpress) return options;
  return options.filter(({ value }) =>
    isBefore(addMinutes(new Date(value), DELIVERY_TIME_GAP_EXPRESS - 1), new Date(last(options).value))
  );
};

export const generateDropTimeOptions = ({ startDate, endDate, isExpress, withoutPastLimit }) => {
  if (!startDate && !endDate) return [];

  if (isExpress) {
    return generateTimePickerData(startDate, { withoutPastLimit }).filter(({ value }) => {
      const date = new Date(value);
      return (
        !isBefore(date, addMinutes(startDate, DELIVERY_TIME_GAP_EXPRESS - 1)) &&
        isBefore(date, addMinutes(startDate, DELIVERY_TIME_GAP - 1))
      );
    });
  }
  return generateTimePickerData(startOfDay(endDate), { withoutPastLimit }).filter(({ value }) => {
    return isAfter(new Date(value), addMinutes(startDate, DELIVERY_TIME_GAP - 1));
  });
};

export const generateNewOrderRecipient = ({ firstName, lastName, phone, email } = {}) => ({
  name: firstName && lastName ? `${firstName} ${lastName}` : DEFAULT_LOCATION_DATA.recipient.name,
  phone: phone ?? DEFAULT_LOCATION_DATA.recipient.phone,
  email: email ?? DEFAULT_LOCATION_DATA.recipient.email,
});

export const generateNewOrder = (contact) => ({
  startDate: null,
  endDate: null,
  isExpress: false,
  pick_up: { ...DEFAULT_LOCATION_DATA, notes: "", recipient: generateNewOrderRecipient(contact) },
  drops: [generateNewDrop()],
  repeat_settings: DEFAULT_REPEAT_ORDER_SETTINGS,
});

export const prettifyTaskData = (date, address) => {
  return `${formatHours(date)} - ${address.location} | ${address.postcode}`;
};
