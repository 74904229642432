import { cx } from "@emotion/css";
import { ArrowBackIosRounded as ArrowBackIcon } from "@mui/icons-material";
import { Backdrop, Fade } from "@mui/material";
import useDeviceType from "hooks/useDeviceType";
import IconButton from "library/Atoms/Button/IconButton";
import Drawer from "library/Atoms/Drawer";
import { PropTypes } from "prop-types";
import { styles, stylesInner } from "./styles";

const DetailsDrawer = ({
  id,
  className,
  classNameInner,
  onClose,
  contentSection,
  contentControlsSection,
  contentInnerSection,
  open,
  openInner,
  focus,
}) => {
  const { isMobile, isTablet, isDesktop } = useDeviceType();

  return (
    <>
      <Drawer
        id={id}
        open={open}
        className={cx(
          styles.drawer(isTablet),
          "col-md-6 col-12 px-0",
          [isTablet, isDesktop].some(Boolean) && open && styles.drawerOpen,
          [isTablet, isDesktop].some(Boolean) && !open && styles.drawerClose,
          className
        )}
        contentClassName={cx(styles.drawerContent, "d-flex flex-column position-sticky h-100 overflow-hidden")}
        variant={isMobile ? "temporary" : "permanent"}
        anchor={isMobile ? "bottom" : "right"}
      >
        {contentSection}

        {openInner && (
          <>
            <IconButton
              id={`${id}-close-btn`}
              className={cx(styles.closeIconBtn, "position-absolute")}
              onClick={onClose}
              isRounded
            >
              <ArrowBackIcon fontSize="small" />
            </IconButton>

            <div className={cx(styles.controlsSection, "position-absolute")}>{contentControlsSection}</div>
          </>
        )}

        <Drawer
          id={id ? `${id}-inner` : null}
          open={openInner}
          className={cx(
            stylesInner.drawer(isMobile),
            openInner ? stylesInner.drawerOpen : stylesInner.drawerClose,
            classNameInner
          )}
          contentClassName={cx(stylesInner.drawerContent, "position-sticky h-100 overflow-visible text-fs-12")}
          anchor="bottom"
          variant="persistent"
        >
          {contentInnerSection}
        </Drawer>
      </Drawer>

      <Fade in={focus}>
        <Backdrop open onClick={onClose} />
      </Fade>
    </>
  );
};

DetailsDrawer.defaultProps = {
  id: null,
  className: null,
  classNameInner: null,
  contentSection: null,
  contentControlsSection: null,
};

DetailsDrawer.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  classNameInner: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  contentSection: PropTypes.node,
  contentControlsSection: PropTypes.node,
  contentInnerSection: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  openInner: PropTypes.bool.isRequired,
  focus: PropTypes.bool.isRequired,
};

export default DetailsDrawer;
