import { cx } from "@emotion/css";
import { ButtonBase } from "@mui/material";
import useCookie from "hooks/useCookie";
import useDeviceType from "hooks/useDeviceType";
import { MenuClosedIcon, MenuOpenedIcon, PedivanLogo } from "library/Atoms/SVGIcons";
import PropTypes from "prop-types";
import { useCallback } from "react";
import styles from "./styles";

const SideMenuTopPart = ({ open, onToggleDrawer }) => {
  const { isDesktop } = useDeviceType();
  const [, setCookie] = useCookie();

  const handleToggleDrawer = useCallback(() => {
    setCookie("drawerPosition", !open);
    onToggleDrawer();
  }, [open, onToggleDrawer, setCookie]);

  return (
    <div
      className={cx(
        styles.menuTopPart,
        open ? styles.drawerOpened : styles.drawerClosed(!isDesktop),
        !isDesktop && "w-100",
        "position-sticky d-flex flex-column justify-content-center align-items-stretch px-0 bg-color-grey900"
      )}
      data-cy="sidemenu-top-part"
    >
      <div className="d-flex flex-nowrap px-4">
        <ButtonBase id="toogle-sidemenu-btn" onClick={handleToggleDrawer} aria-checked={!!open}>
          {open ? <MenuOpenedIcon /> : <MenuClosedIcon />}
        </ButtonBase>

        {(open || !isDesktop) && <PedivanLogo className="ml-4" />}
      </div>
    </div>
  );
};

SideMenuTopPart.propTypes = {
  open: PropTypes.bool.isRequired,
  onToggleDrawer: PropTypes.func.isRequired,
};

export default SideMenuTopPart;
