import useOpenClose from "hooks/useOpenClose";
import Prompt from "library/Atoms/Prompt";
import Breadcrumbs from "library/Molecules/Breadcrumbs";
import SEO from "library/Molecules/SEO";
import { values } from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { NEW_ORDER_FORM_STEPS } from "../../constants";
import BookingForm from "./components/BookingForm/BookingForm";
import CalculatePriceForm from "./components/CalculatePriceForm/CalculatePriceForm";
import ImportOrderForm from "./components/ImportOrderForm/ImportOrderForm";
import SetDeliveryForm from "./components/SetDeliveryForm/SetDeliveryForm";
import ThankYouForm from "./components/ThankYouForm/ThankYouForm";
import useNewOrderForm from "./hooks/useNewOrderForm";
import NewOrderPageLayout from "./layouts/NewOrderPageLayout/NewOrderPageLayout";
import ConfirmResetOrderModal from "./modals/ConfirmResetOrderModal/ConfirmResetOrderModal";
import ImportOrderModal from "./modals/ImportOrderModal/ImportOrderModal";
import withFastQuoteData from "./withFastQuoteData";
import withImportedOrder from "./withImportedOrder";
import withNewOrderForm from "./withNewOrderForm";

const NewOrder = ({ handleImportOrder }) => {
  const { t } = useTranslation();

  const [
    ,
    {
      dirty,

      step,
      handleSetDeliveryStep,
      handleSetCalculateStep,
      handleSetBookingStep,

      resetForm: resetNewOrderForm,
    },
  ] = useNewOrderForm();

  const [isConfirmationModalOpen, openConfirmationModal, closeConfirmationModal] = useOpenClose(false);
  const [isImportModalOpen, openImportModal, closeImportModal] = useOpenClose();

  return (
    <NewOrderPageLayout
      breadcrumbsSection={
        step !== NEW_ORDER_FORM_STEPS.FINAL && (
          <Breadcrumbs
            id="new-order-breadcrumbs"
            step={values(NEW_ORDER_FORM_STEPS).findIndex((currStep) => currStep === step)}
            data={[
              { label: t("newOrder.breadcrumbs.setDeliveryStep"), onClick: handleSetDeliveryStep },
              { label: t("newOrder.breadcrumbs.calculateStep"), onClick: handleSetCalculateStep },
              { label: t("newOrder.breadcrumbs.bookingStep"), onClick: handleSetBookingStep },
            ]}
          />
        )
      }
      setDeliveryStepSection={
        step === NEW_ORDER_FORM_STEPS.SET_DELIVERY && (
          <SetDeliveryForm onImport={openImportModal} onReset={openConfirmationModal} />
        )
      }
      calculatePriceStepSection={
        step === NEW_ORDER_FORM_STEPS.CALCULATE && <CalculatePriceForm onReset={openConfirmationModal} />
      }
      bookingStepSection={step === NEW_ORDER_FORM_STEPS.BOOKING && <BookingForm onReset={openConfirmationModal} />}
      thankYouStepSection={step === NEW_ORDER_FORM_STEPS.FINAL && <ThankYouForm />}
      modals={
        <>
          {isImportModalOpen && (
            <ImportOrderModal onClose={closeImportModal}>
              <ImportOrderForm onSubmit={handleImportOrder} onClose={closeImportModal} />
            </ImportOrderModal>
          )}

          {isConfirmationModalOpen && (
            <ConfirmResetOrderModal
              onConfirm={resetNewOrderForm}
              onDecline={closeConfirmationModal}
              onClose={closeConfirmationModal}
            />
          )}

          <Prompt when={dirty && step !== NEW_ORDER_FORM_STEPS.FINAL}>
            {({ onLeave, onStay }) => (
              <ConfirmResetOrderModal onConfirm={onLeave} onDecline={onStay} onClose={closeConfirmationModal} />
            )}
          </Prompt>
        </>
      }
    >
      <SEO title={t("seo.helmetTitle", { name: t("seo.pages.newOrder") })} />
    </NewOrderPageLayout>
  );
};

NewOrder.propTypes = {
  handleImportOrder: PropTypes.func.isRequired,
};

export default withNewOrderForm(withFastQuoteData(withImportedOrder(NewOrder)));
