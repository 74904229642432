import ScrollLayout from "library/Layouts/ScrollLayout";
import { PropTypes } from "prop-types";

const LibraryListLayout = ({ filtersSection, listSection, listSkeletonSection, emptyListSection }) => (
  <>
    <div className="d-flex flex-wrap justify-content-between gap-2 py-md-2 mt-4 mb-3 pl-1 pr-3">{filtersSection}</div>

    <ScrollLayout className="d-flex flex-1 flex-column">{listSkeletonSection || listSection}</ScrollLayout>

    {emptyListSection}
  </>
);

LibraryListLayout.propTypes = {
  filtersSection: PropTypes.node.isRequired,
  listSection: PropTypes.node.isRequired,
  listSkeletonSection: PropTypes.node.isRequired,
  emptyListSection: PropTypes.node.isRequired,
};

export default LibraryListLayout;
