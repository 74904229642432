import { unwrapResult } from "@reduxjs/toolkit";
import { useFormikContext } from "formik";
import FilePicker from "library/Molecules/FilePicker";
import { isEmpty } from "lodash";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { parseCsvQuoteRequest } from "store/requests/newOrder.requests";

const ImportCsvQuoteForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { setFieldValue, setFieldError, setStatus } = useFormikContext();

  const onUploadFile = useCallback(
    (name, file) => {
      setFieldValue(name, file).then(() => {
        if (isEmpty(file)) {
          setFieldValue("order", null);
        } else {
          setStatus("loading");
          setFieldValue("order", null);

          dispatch(parseCsvQuoteRequest(file))
            .then(unwrapResult)
            .then((res) => {
              setFieldValue("label", null);
              setFieldValue("order", res.data);
              setStatus(null);
            })
            .catch((error) => {
              setStatus(null);
              setFieldError("file", error);
            });
        }
      });
    },
    [setFieldValue, dispatch, setFieldError, setStatus]
  );

  return (
    <FilePicker
      id="import-new-order-csv"
      name="file"
      label={t("newOrder.forms.importForm.uploadFileLabel")}
      placeholder={t("newOrder.forms.importForm.uploadFilePlaceholder")}
      onUpload={onUploadFile}
    />
  );
};

export default ImportCsvQuoteForm;
