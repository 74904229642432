import { combineReducers, configureStore } from "@reduxjs/toolkit";
import contactSlice from "./features/contact.store";
import invoicesSlice from "./features/invoice.store";
import { libraryCombinedSlice as librarySlice } from "./features/library.store";
import localizationSlice from "./features/localization.store";
import orderSlice from "./features/order.store";
import toastSlice from "./features/toast.store";

const combinedReducer = combineReducers({
  library: librarySlice,
  contact: contactSlice,
  invoice: invoicesSlice,
  order: orderSlice,
  localization: localizationSlice,
  toast: toastSlice,
});

const rootReducer = (state, action) => {
  if (action.type === "resetStore") {
    state = {
      ...state,
      library: librarySlice.initialState,
      contact: contactSlice.initialState,
      invoice: invoicesSlice.initialState,
      order: orderSlice.initialState,
    };
  }

  return combinedReducer(state, action);
};

export default configureStore({ reducer: rootReducer, devTools: true });
