import BriefItemDetailsLayout from "library/Layouts/DetailsLayout/BriefItemDetailsLayout";
import { PropTypes } from "prop-types";
import { Trans, useTranslation } from "react-i18next";
import { InvoicePriceType } from "types/models/Invoice";
import { formatDate } from "utility/date-fns";
import { convertTimestampToDate, formatPrice } from "utility/helpers/general";

const InvoiceBriefInfo = ({ id, createdAt, dueAt, lines, price }) => {
  const { t } = useTranslation();

  return (
    <BriefItemDetailsLayout
      titleSection={t("invoices.invoice.id", { id })}
      descriptionSection={
        <>
          <span className="text-truncate text-center">
            {formatDate(convertTimestampToDate(createdAt))}

            <span className="px-1">-</span>

            {formatDate(convertTimestampToDate(dueAt))}
          </span>

          <span className="px-1">|</span>

          <span className="text-truncate text-center">{t("invoices.invoice.linesCount", { count: lines })}</span>

          <span className="px-1">|</span>

          <span className="text-truncate text-center">
            <Trans
              i18nKey="utils.pricing.inclVAT"
              tOptions={{ price: formatPrice(price.total, price.currency) }}
              components={{ span: <span /> }}
              defaults="<span>{{price}}</span> inc. VAT"
            />
          </span>
        </>
      }
    />
  );
};

InvoiceBriefInfo.defaultProps = {
  lines: 0,
};

InvoiceBriefInfo.propTypes = {
  id: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  createdAt: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  dueAt: PropTypes.any.isRequired,
  lines: PropTypes.number,
  price: InvoicePriceType.isRequired,
};

export default InvoiceBriefInfo;
