import useDeviceType from "hooks/useDeviceType";
import { size } from "lodash";
import useNewOrderForm from "pages/NewOrder/hooks/useNewOrderForm";
import NewOrderDropDetailsLayout from "pages/NewOrder/layouts/NewOrderDetailsLayout/NewOrderDropDetailsLayout/NewOrderDropDetailsLayout";
import NewOrderSummaryLayout from "pages/NewOrder/layouts/NewOrderDetailsLayout/NewOrderSummaryLayout/NewOrderSummaryLayout";
import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { formatDate, formatDateFlat, formatHours } from "utility/date-fns";
import { formatPrice } from "utility/helpers/general";
import { getPriceDiscount, prettifyPackagesAmount } from "utility/helpers/orders";
import { NEW_ORDER_FORM_STEPS, REPEAT_ORDER_TYPES } from "../../../../constants";
import MessageLayout from "../../layouts/MessageLayout/MessageLayout";
import IncreasedPriceMessage from "./IncreasedPriceMessage/IncreasedPriceMessage";
import styles from "./NewOrderDetails.styles";

const NewOrderDetails = () => {
  const { t } = useTranslation();
  const { isMobile } = useDeviceType();

  const [
    {
      order: { startDate, endDate, pick_up, drops, isExpress, repeat_settings },
      price,
    },
    { step },
  ] = useNewOrderForm();

  const { discount } = useMemo(() => getPriceDiscount(price), [price]);

  const newOrderDate = useMemo(() => formatDate(startDate), [startDate]);
  const newOrderPickUpTime = useMemo(() => formatHours(startDate), [startDate]);
  const newOrderDropOffTime = useMemo(() => formatHours(endDate), [endDate]);

  const isLastStep = useMemo(() => step === NEW_ORDER_FORM_STEPS.BOOKING, [step]);

  return (
    <NewOrderSummaryLayout
      className={isMobile ? "order-first my-4" : styles.container(isLastStep)}
      dateSection={newOrderDate}
      pickUpSection={`${newOrderPickUpTime} - ${pick_up.location} | ${pick_up.postcode}`}
      pickUpNotesSection={pick_up.notes}
      dropsSection={drops.map(({ reference, location, postcode, packages, notes }, dindex) => (
        <NewOrderDropDetailsLayout
          key={reference}
          titleSection={`${t("newOrder.orderDetails.dropOff")} ${size(drops) > 1 ? `#${dindex + 1}` : ""}`}
          dropAddressSection={`${newOrderDropOffTime} - ${location} | ${postcode}`}
          packageTypeSection={prettifyPackagesAmount(packages)}
          notesSection={notes || null}
        />
      ))}
      priceSection={
        isLastStep && (
          <Trans
            i18nKey="utils.pricing.exclVAT"
            context={discount > 0 ? "discount" : ""}
            tOptions={{
              price: formatPrice(price.price, price.currency),
              discount: formatPrice(price.starting_price, price.currency),
            }}
            components={{ del: <del /> }}
            defaults={discount > 0 ? "{{price}} <del>({{discount}})</del> excl. VAT" : "{{price}} excl. VAT"}
          />
        )
      }
      repeatOrderSection={
        repeat_settings.type === REPEAT_ORDER_TYPES.EXACT_DATES &&
        repeat_settings.dates.map((dateToRepeat) => formatDateFlat(dateToRepeat)).join(", ")
      }
      messagesSection={
        <>
          <IncreasedPriceMessage startDate={startDate} isExpress={isExpress} />

          {isLastStep && (
            <MessageLayout
              className="col-12 mt-3 px-0"
              labelSection={<>{t("newOrder.estimation.notFinalPriceMessage")}</>}
              data-cy="not-final-price-message"
            />
          )}
        </>
      }
      collapse={size(drops) > 4}
      data-cy="new-order-details"
    />
  );
};

export default NewOrderDetails;
