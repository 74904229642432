import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { AuthContextProvider } from "contexts/AuthContext";
import { SystemProvider } from "contexts/SystemContext";
import Loading from "library/Atoms/Loading";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import store from "./store";

if (process.env.REACT_APP_SENTRY_SWITCH) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    integrations: [new Integrations.BrowserTracing()],
    release: `pedivan-web@${process.env.REACT_APP_VERSION}`,
    environment: process.env.REACT_APP_ENVIRONMENT,
    tracesSampleRate: 1.0,
    ignoreErrors: ["Non-Error promise rejection captured"],
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<Loading />}>
      <Provider store={store}>
        <AuthContextProvider>
          <SystemProvider>
            <App />
          </SystemProvider>
        </AuthContextProvider>
      </Provider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

if (window.Cypress) {
  window.store = store;
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
