import { cx } from "@emotion/css";
import SavedAddressesIcon from "@mui/icons-material/StarBorderRounded";
import useDeviceType from "hooks/useDeviceType";
import IconButton from "library/Atoms/Button/IconButton";
import SecondaryButton from "library/Atoms/Button/SecondaryButton";
import useOrders from "pages/Dashboard/hooks/useOrders";
import { PropTypes } from "prop-types";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { retrieveContact } from "store/features/contact.store";
import { saveOrderRequest } from "store/requests/library.requests";
import { LIBRARY_STORAGE_LIMIT } from "../../../../../constants";

const SaveOrderButton = ({ className }) => {
  const { t } = useTranslation();
  const { isMobile } = useDeviceType();
  const dispatch = useDispatch();

  const contact = useSelector(retrieveContact);

  const [{ selectedOrder: { id, reference } = {} }] = useOrders();

  const isStorageLimitExceeded = useMemo(() => {
    return contact.library?.orders >= LIBRARY_STORAGE_LIMIT;
  }, [contact.library?.orders]);

  const onSaveOrder = useCallback(
    () => dispatch(saveOrderRequest({ id, label: t("dashboard.order.copyOfOrderLabel", { reference }) })),
    [dispatch, t, reference, id]
  );

  if (isMobile) {
    return (
      <IconButton
        id={`save-${reference}-order-btn-mobile`}
        onClick={onSaveOrder}
        disabled={isStorageLimitExceeded}
        isRounded
      >
        <SavedAddressesIcon />
      </IconButton>
    );
  }

  return (
    <SecondaryButton
      id={`save-${reference}-order-btn`}
      className={cx(className, "w-100")}
      contentClassName="text-truncate text-fs-12"
      label="Save order"
      onClick={onSaveOrder}
      disabled={isStorageLimitExceeded}
    />
  );
};

SaveOrderButton.defaultProps = {
  className: "",
};

SaveOrderButton.propTypes = {
  className: PropTypes.string,
};

export default SaveOrderButton;
