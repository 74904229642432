import { createSlice } from "@reduxjs/toolkit";
import { isEqual } from "lodash";
import {
  createNewAddressRequest,
  deleteAddressRequest,
  deleteSavedOrderRequest,
  saveOrderRequest,
} from "store/requests/library.requests";
import { DEFAULT_CITY, DEFAULT_INVOICE_SETTINGS, INVOICE_PERIODS } from "../../constants";
import {
  fetchContactRequest,
  reacceptContactTemrsRequest,
  updateContactLocaleRequest,
  updateContactRequest,
} from "../requests/contact.requests";

export const contactSlice = createSlice({
  name: "contact",
  initialState: {
    profile: {},
    status: "idle",
  },
  extraReducers: {
    [fetchContactRequest.pending]: (state) => {
      state.status = "loading";
    },
    [fetchContactRequest.fulfilled]: (state, { payload: { data } }) => {
      const { city = DEFAULT_CITY, invoice = DEFAULT_INVOICE_SETTINGS, ...user } = { ...state.profile, ...data };

      if (invoice.frequency === INVOICE_PERIODS.biweekly) invoice.period = "biweekly";

      state.status = "succeeded";
      state.profile = { ...user, city, invoice };
    },
    [fetchContactRequest.rejected]: (state) => {
      state.status = "failed";
    },
    [updateContactRequest.fulfilled]: (state, { payload: { data } }) => {
      if (isEqual(state.profile, data)) return;
      if (data?.invoice?.frequency === INVOICE_PERIODS.biweekly) data.invoice.period = "biweekly";
      state.profile = data;
    },
    [updateContactLocaleRequest.fulfilled]: (state, { payload: { data } }) => {
      state.profile = data;
    },
    [reacceptContactTemrsRequest.fulfilled]: (state, { payload: { data } }) => {
      state.profile = data;
    },
    [saveOrderRequest.fulfilled]: (state) => {
      state.profile = {
        ...state.profile,
        library: { ...state.profile.library, orders: state.profile.library.orders + 1 },
      };
    },
    [deleteSavedOrderRequest.fulfilled]: (state) => {
      state.profile = {
        ...state.profile,
        library: { ...state.profile.library, orders: state.profile.library.orders - 1 },
      };
    },
    [createNewAddressRequest.fulfilled]: (state) => {
      state.profile = {
        ...state.profile,
        library: { ...state.profile.library, addresses: state.profile.library.addresses + 1 },
      };
    },
    [deleteAddressRequest.fulfilled]: (state) => {
      state.profile = {
        ...state.profile,
        library: { ...state.profile.library, addresses: state.profile.library.addresses - 1 },
      };
    },
  },
});

export const retrieveContact = (state) => state.contact.profile;
export const retrieveContactCity = (state) => state.contact.profile?.city || DEFAULT_CITY;

export const retrieveContactStatus = (state) => {
  const { status } = state.contact;
  return { idle: status === "idle", loading: status === "loading", failed: status === "failed" };
};

export default contactSlice.reducer;
