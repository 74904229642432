import { unwrapResult } from "@reduxjs/toolkit";
import { FormikProvider, useFormik } from "formik";
import useOpenClose from "hooks/useOpenClose";
import Prompt from "library/Atoms/Prompt";
import FormControls from "library/Molecules/FormControls";
import ProfileFormLayout from "pages/Profile/layouts/ProfileFormLayout/ProfileFormLayout";
import ConfirmProfileResetModal from "pages/Profile/modals/ConfirmProfileResetModal/ConfirmProfileResetModal";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { retrieveContact } from "store/features/contact.store";
import { updateContactRequest } from "store/requests/contact.requests";
import ContactForm from "./ContactForm/ContactForm";
import InvoiceForm from "./InvoiceForm/InvoiceForm";
import ProfileFormSchema from "./ProfileForm.validation";

const ProfileForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const contact = useSelector(retrieveContact);

  const profileForm = useFormik({
    initialValues: contact,
    onSubmit: (partner, { setSubmitting }) => {
      dispatch(updateContactRequest(partner))
        .then(unwrapResult)
        .then(() => setSubmitting(false))
        .catch(() => setSubmitting(false));
    },
    validationSchema: ProfileFormSchema,
    enableReinitialize: true,
  });
  const { submitForm, isValid, isSubmitting, dirty: isFormDirty, resetForm, errors, values } = profileForm;

  const [isConfirmationModalOpen, openConfirmationModal, closeConfirmationModal] = useOpenClose(false);

  return (
    <FormikProvider value={profileForm}>
      <ProfileFormLayout
        contactFormSection={<ContactForm />}
        invoiceFormSection={<InvoiceForm />}
        controlsSection={
          <FormControls
            className="mt-5"
            resetBtnOptions={{
              id: "reset-profile-changes-button",
              label: t("common.buttons.resetBtn"),
              onClick: openConfirmationModal,
              disabled: !isFormDirty || isSubmitting,
            }}
            submitBtnsOptions={[
              {
                id: "update-profile-button",
                label: t("common.buttons.updateBtn"),
                onClick: submitForm,
                disabled: !isValid || isSubmitting || !isFormDirty,
                loading: isSubmitting,
              },
            ]}
          />
        }
        modals={
          <>
            {isConfirmationModalOpen && (
              <ConfirmProfileResetModal
                onConfirm={resetForm}
                onDecline={closeConfirmationModal}
                onClose={closeConfirmationModal}
              />
            )}

            <Prompt when={isFormDirty}>
              {({ onLeave, onStay }) => (
                <ConfirmProfileResetModal onConfirm={onLeave} onDecline={onStay} onClose={closeConfirmationModal} />
              )}
            </Prompt>
          </>
        }
      />
    </FormikProvider>
  );
};

export default ProfileForm;
