import ConfirmationModal from "library/Molecules/Modal/ConfirmationModal";
import { PropTypes } from "prop-types";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

const ConfirmProfileResetModal = ({ onConfirm, onDecline, onClose }) => {
  const { t } = useTranslation();

  const handleConfirm = useCallback(() => {
    onConfirm();
    onClose();
  }, [onClose, onConfirm]);

  const handleDecline = useCallback(() => {
    onDecline();
    onClose();
  }, [onClose, onDecline]);

  return (
    <ConfirmationModal
      id="reset-profile-form-modal"
      title={t("common.modals.confirmModal.title")}
      description={t("common.modals.confirmModal.description")}
      onConfirm={handleConfirm}
      onDecline={handleDecline}
      declineBtnText={t("common.buttons.backToBtn", { context: "profile" })}
      confirmBtnText={t("common.modals.confirmModal.resetChangesBtn")}
    />
  );
};

ConfirmProfileResetModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ConfirmProfileResetModal;
