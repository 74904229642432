import { createTheme, ThemeProvider } from "@mui/material/styles";
import ReacceptTermsModal from "features/ReacceptTermsModal";
import SideMenu from "features/SideMenu";
import SystemLoadFailModal from "features/SystemLoadFailModal";
import useApiResponseHandler from "hooks/useApiResponseHandler";
import useContactTerms from "hooks/useContactTerms";
import useSystemState from "hooks/useSystemState";
import Loading from "library/Atoms/Loading";
import PageLayout from "library/Layouts/PageLayout/PageLayout";
import { compact } from "lodash";
import Dashboard from "pages/Dashboard/Dashboard";
import Invoices from "pages/Invoices/Invoices";
import Library from "pages/Library/Library";
import Login from "pages/Login/Login";
import NewOrder from "pages/NewOrder/NewOrder";
import Profile from "pages/Profile/Profile";
import Register from "pages/Register/Register";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { createBrowserRouter, Navigate, Outlet, RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AuthenticatedRoute from "routes/AuthenticatedRoute";
import UnauthenticatedRoute from "routes/UnauthenticatedRoute";
import { retrieveContactLocalization } from "store/features/localization.store";
import { getMuiLocale } from "utility/helpers/locales";
import "utility/i18next";
import "utility/ui/globalStyles";
import theme from "utility/ui/theme";
import "./App.css";

const App = () => {
  useApiResponseHandler();
  const { i18n } = useTranslation();

  const { isSystemLoading, isSystemLoadingFailed } = useSystemState();
  const shouldReacceptTerms = useContactTerms();

  const city = useSelector(retrieveContactLocalization);

  const router = createBrowserRouter([
    { path: "/dashboard", element: <Navigate to="/" /> },
    { path: "/saved-addresses", element: <Navigate to="/library" /> },
    {
      element: <UnauthenticatedRoute />,
      children: [
        { path: "/register", element: <Register /> },
        { path: "/login", element: <Login /> },
      ],
    },
    {
      element: <AuthenticatedRoute />,
      children: [
        {
          element: (
            <PageLayout sideMenu={(sideMenuChildren) => <SideMenu>{sideMenuChildren}</SideMenu>}>
              <Outlet />
            </PageLayout>
          ),
          children: compact([
            city?.disabled ? { path: "/new-order", element: <Navigate to="/profile" /> } : null,
            { path: "/", element: <Dashboard /> },
            { path: "/profile", element: <Profile /> },
            { path: "/new-order", element: <NewOrder /> },
            { path: "/library", element: <Library /> },
            { path: "/invoices", element: <Invoices /> },
          ]),
        },
      ],
    },
  ]);

  const themeWithLocale = useMemo(() => createTheme(theme, getMuiLocale(i18n.language)), [i18n.language]);

  return (
    <ThemeProvider theme={themeWithLocale}>
      {(isSystemLoading || isSystemLoadingFailed) && <Loading />}

      <RouterProvider router={router} />

      {isSystemLoadingFailed && <SystemLoadFailModal />}

      {shouldReacceptTerms && <ReacceptTermsModal />}

      <ToastContainer />
    </ThemeProvider>
  );
};

export default App;
