import { cx } from "@emotion/css";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton } from "@mui/material";
import { useFormikContext } from "formik";
import useDebounce from "hooks/useDebounce";
import DefaultInput from "library/Atoms/Input/DefaultInput";
import { isEmpty } from "lodash";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles";

const SearchFilter = () => {
  const { t } = useTranslation();

  const {
    values: { search },
    setFieldValue,
  } = useFormikContext();

  const onChange = useCallback(
    (name, value) => {
      if (search === value) return;
      setFieldValue(name, value);
    },
    [search, setFieldValue]
  );
  const onClearInput = useCallback(() => onChange("search", ""), [onChange]);

  const handleChangeValue = useDebounce(onChange, 450);

  return (
    <DefaultInput
      id="list-search-filter"
      name="search"
      className={styles.container}
      placeholder={t("features.filtersForm.searchLabel")}
      value={search}
      InputProps={{
        className: cx(styles.input, "w-100"),
        startAdornment: <SearchIcon className={cx(styles.icon, "mr-2")} />,
        endAdornment: !isEmpty(search) && (
          <IconButton className="p-0" onClick={onClearInput} data-cy="list-search-filter-clear-btn">
            <CloseRoundedIcon className={cx(styles.icon)} />
          </IconButton>
        ),
      }}
      onChange={handleChangeValue}
      variant="standard"
    />
  );
};

export default SearchFilter;
