import { cx } from "@emotion/css";
import { FormControl, FormHelperText } from "@mui/material";
import { PropTypes } from "prop-types";
import styles from "./styles";

const InputLayout = ({ name, className, label, error, helper, children, required }) => (
  <div className={cx(className, "d-flex flex-column text-fw-semibold")}>
    {label && (
      <div className={cx(styles.label, "text-fs-12 pb-3")}>
        {label} {required && <span className="text-color-error">*</span>}
      </div>
    )}

    <FormControl error={!!error} data-cy={`${name}-form-control`}>
      {children}

      <FormHelperText className={cx(styles.helperText, "position-absolute")} data-cy={`${name}-helper`}>
        {error || helper}
      </FormHelperText>
    </FormControl>
  </div>
);

InputLayout.defaultProps = {
  className: "",
  label: "",
  error: "",
  helper: "",
  required: false,
};

InputLayout.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  error: PropTypes.string,
  helper: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  children: PropTypes.node.isRequired,
  required: PropTypes.bool,
};

export default InputLayout;
