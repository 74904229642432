import { PropTypes } from "prop-types";

const NewOrderPageLayout = ({
  breadcrumbsSection,
  setDeliveryStepSection,
  calculatePriceStepSection,
  bookingStepSection,
  thankYouStepSection,
  children,
  modals,
}) => (
  <div
    className="d-flex flex-1 h-fit flex-column align-items-stretch container py-5 px-4 px-md-5"
    data-cy="new-order-page-layout"
  >
    {breadcrumbsSection}

    {setDeliveryStepSection}
    {calculatePriceStepSection}
    {bookingStepSection}
    {thankYouStepSection}

    {children}

    {modals}
  </div>
);

NewOrderPageLayout.propTypes = {
  breadcrumbsSection: PropTypes.node.isRequired,
  setDeliveryStepSection: PropTypes.node.isRequired,
  calculatePriceStepSection: PropTypes.node.isRequired,
  bookingStepSection: PropTypes.node.isRequired,
  thankYouStepSection: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  modals: PropTypes.node.isRequired,
};

export default NewOrderPageLayout;
