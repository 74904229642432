import { createAsyncThunk } from "@reduxjs/toolkit";
import { isBefore, startOfDay, subDays } from "date-fns";
import { isEmpty, keys } from "lodash";
import PartnerApi from "utility/api";
import { checkIsAdmin } from "utility/helpers/user";

export const fetchOrdersRequest = createAsyncThunk(
  "order/fetchOrdersRequest",
  async (page = 1, { getState, rejectWithValue }) => {
    try {
      const { order } = getState();

      const isAdmin = await checkIsAdmin();
      const archiveLimit = subDays(startOfDay(new Date()), 7);

      let filters = { ...order.filters };
      if (isAdmin && (!filters.start || isBefore(new Date(filters.start), archiveLimit))) {
        filters = { ...filters, start: new Date(archiveLimit).toISOString() };
      }

      const result = await PartnerApi.orders({ page, filters });
      return result;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const fetchFilteredOrdersRequest = createAsyncThunk(
  "order/fetchFilteredOrdersRequest",
  async (filters, { getState, rejectWithValue }) => {
    const { order } = getState();

    let currFilters = { ...filters };

    if (currFilters.status === "all") currFilters.status = null;
    currFilters = keys(currFilters).reduce((prev, field) => {
      if (isEmpty(currFilters[field])) return prev;
      return { ...prev, [field]: currFilters[field] };
    }, {});

    const isAdmin = await checkIsAdmin();
    const archiveLimit = subDays(startOfDay(new Date()), 7);

    if (isAdmin && (!currFilters.start || isBefore(new Date(currFilters.start), archiveLimit))) {
      currFilters = { ...currFilters, start: new Date(archiveLimit).toISOString() };
    }

    try {
      const result = await PartnerApi.orders({ page: order.pagination.active_page, filters: currFilters });
      return result;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const fetchOrderPositionRequest = createAsyncThunk(
  "order/fetchOrderPositionRequest",
  async (id, { rejectWithValue }) => {
    try {
      const result = await PartnerApi.riderPosition(id);
      return result;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);
