import DeleteAddressIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditAddressIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import useOpenClose from "hooks/useOpenClose";
import IconButton from "library/Atoms/Button/IconButton";
import LibraryConfirmDeleteModal from "pages/Library/modals/LibraryConfirmDeleteModal/LibraryConfirmDeleteModal";
import AddressSettingsModal from "pages/Library/modals/LibraryItemSettingsModal/AddressSettingsModal/AddressSettingsModal";
import { useSelector } from "react-redux";
import { retrieveSelectedAddress } from "store/features/library.store";
import AddressSettingsForm from "../../AddressSettingsForm/AddressSettingsForm";

const AddressControls = () => {
  const { id } = useSelector(retrieveSelectedAddress);

  const [isConfirmDeleteModalOpen, openConfirmDeleteModal, closeConfirmDeleteModal] = useOpenClose(false);
  const [isEditAddressModalOpen, openEditAddressModal, closeEditAddressModal] = useOpenClose(false);

  return (
    <>
      <div className="d-flex flex-column gap-3">
        <IconButton id={`edit-address-${id}-btn-mobile`} onClick={openEditAddressModal} color="yellow" isRounded>
          <EditAddressIcon />
        </IconButton>

        <IconButton id={`delete-address-${id}-btn-mobile`} onClick={openConfirmDeleteModal} color="error" isRounded>
          <DeleteAddressIcon />
        </IconButton>
      </div>

      {isConfirmDeleteModalOpen && <LibraryConfirmDeleteModal onClose={closeConfirmDeleteModal} isAddress />}

      {isEditAddressModalOpen && (
        <AddressSettingsModal onClose={closeEditAddressModal} isEdit>
          <AddressSettingsForm onClose={closeEditAddressModal} isEdit />
        </AddressSettingsModal>
      )}
    </>
  );
};

export default AddressControls;
