import { cx } from "@emotion/css";
import { Fab as MuiFab, Zoom } from "@mui/material";
import { PropTypes } from "prop-types";
import styles from "./styles";

const Fab = ({ open, color, onClick, Icon, ...restProps }) => (
  <Zoom in={open}>
    <MuiFab className={cx(styles.fab, "position-sticky")} onClick={onClick} color={color} size="medium" {...restProps}>
      <Icon className={styles.icon} />
    </MuiFab>
  </Zoom>
);

Fab.defaultProps = {
  color: "primary",
  Icon: null,
};

Fab.propTypes = {
  open: PropTypes.bool.isRequired,
  color: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  Icon: PropTypes.object,
};

export default Fab;
