import Accordion from "library/Atoms/Accordion";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ProofOfDeliveryType from "types/models/ProofOfDelivery";
import { formatFullDate } from "utility/date-fns";
import styles from "./ProofOfDelivery.styles";

const ProofOfDelivery = ({ photoUrl, signatureUrl, geolocation, additional_note, delivery_time }) => {
  const { t } = useTranslation();

  const [accordionToOpen, setAccordionToOpen] = useState(null);

  return (
    <>
      <div className="my-3">
        {delivery_time && (
          <div className="mb-1">
            <span className="text-color-grey400 pr-2">{t("dashboard.modals.proofOfDeliveryModal.deliveryTime")}:</span>
            <span>{formatFullDate(delivery_time)}</span>
          </div>
        )}

        {additional_note && (
          <div>
            <span className="text-color-grey400 pr-2">{t("dashboard.modals.proofOfDeliveryModal.note")}:</span>
            <span>{additional_note}</span>
          </div>
        )}
      </div>

      <div>
        {photoUrl && (
          <Accordion
            id="photo-proof-accordion"
            open={accordionToOpen === "photo-proof-accordion"}
            label={t("dashboard.modals.proofOfDeliveryModal.photo")}
            onClick={setAccordionToOpen}
          >
            <img className={styles.image} src={photoUrl} alt={t("dashboard.modals.proofOfDeliveryModal.photo")} />
          </Accordion>
        )}

        {signatureUrl && (
          <Accordion
            id="signature-proof-accordion"
            open={accordionToOpen === "signature-proof-accordion"}
            label={t("dashboard.modals.proofOfDeliveryModal.signature")}
            onClick={setAccordionToOpen}
          >
            <img
              className={styles.image}
              src={signatureUrl}
              alt={t("dashboard.modals.proofOfDeliveryModal.signature")}
            />
          </Accordion>
        )}

        {geolocation && (
          <Accordion
            id="tracking-proof-accordion"
            open={accordionToOpen === "tracking-proof-accordion"}
            label={t("dashboard.modals.proofOfDeliveryModal.geolocation")}
            onClick={setAccordionToOpen}
            asyncMount
          >
            <img
              className={styles.image}
              src={`https://maps.googleapis.com/maps/api/staticmap?size=300x200&maptype=roadmap&markers=color:blue%7C${geolocation[0]},${geolocation[1]}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`}
              alt={t("dashboard.modals.proofOfDeliveryModal.geolocation")}
            />
          </Accordion>
        )}
      </div>
    </>
  );
};

ProofOfDelivery.defaultProps = {
  photoUrl: null,
  signatureUrl: null,
  geolocation: null,
  delivery_time: null,
  additional_note: null,
};

ProofOfDelivery.propTypes = ProofOfDeliveryType;

export default ProofOfDelivery;
