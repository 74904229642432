import { unwrapResult } from "@reduxjs/toolkit";
import { FormikProvider, useFormik } from "formik";
import useDebounce from "hooks/useDebounce";
import useOpenClose from "hooks/useOpenClose";
import PrimaryButton from "library/Atoms/Button/PrimaryButton";
import TextButton from "library/Atoms/Button/TextButton";
import TextInput from "library/Atoms/Input/TextInput";
import ModalLayout from "library/Layouts/ModalLayout";
import { isEmpty, omit } from "lodash";
import LibraryConfirmResetModal from "pages/Library/modals/LibraryConfirmResetModal/LibraryConfirmResetModal";
import DropOffForm from "pages/NewOrder/components/SetDeliveryForm/DropOffForm/DropOffForm";
import PickUpForm from "pages/NewOrder/components/SetDeliveryForm/PickUpForm/PickUpForm";
import SetDeliveryFormLayout from "pages/NewOrder/layouts/FormLayout/SetDeliveryFormLayout/SetDeliveryFormLayout";
import PropTypes from "prop-types";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  resetSavedOrderStatus,
  retrieveSavedOrders,
  retrieveSelectedSavedOrder,
  setEditSavedOrderStatus,
} from "store/features/library.store";
import { editSavedOrderRequest } from "store/requests/library.requests";
import OrderSettingsFormValidation from "./OrderSettingsForm.validation";
import OrderTimeForm from "./OrderTimeForm/OrderTimeForm";

const OrderSettingsForm = ({ onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { id, ...orderToEdit } = useSelector(retrieveSelectedSavedOrder);
  const ordersList = useSelector(retrieveSavedOrders);

  const editSavedOrderForm = useFormik({
    initialValues: {
      id,
      ...orderToEdit,
      startDate: new Date(orderToEdit.startDate),
      endDate: new Date(orderToEdit.endDate),
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(false);
      dispatch(editSavedOrderRequest({ id, ...values }))
        .then(unwrapResult)
        .then(() => {
          setSubmitting(false);
          onClose();
        })
        .catch(() => setSubmitting(false));
    },
    validationSchema: OrderSettingsFormValidation,
    enableReinitialize: true,
  });
  const {
    values: { label: selectedLabel, pick_up, drops },
    setFieldValue,
    submitForm,
    resetForm,
    setErrors,
    isSubmitting,
    isValid,
    dirty: isFormDirty,
    errors,
  } = editSavedOrderForm;

  const [isResetModalOpen, openResetModal, closeResetModal] = useOpenClose(false);

  const isLabelAlreadyExist = useMemo(() => {
    const sameOrder = ordersList.find(({ label }) => label === selectedLabel) || {};

    return !isEmpty(sameOrder) && sameOrder?.id !== id;
  }, [ordersList, selectedLabel, id]);

  const debouncedChangeName = useDebounce(setFieldValue);

  useEffect(() => {
    if (!isFormDirty) return;
    const errorKey = t("library.forms.orderSettingsForm.notUniqueLabelError");

    let newErrors = { ...errors };

    if (isLabelAlreadyExist) newErrors = { ...newErrors, label: errorKey };
    else newErrors = errors.label === errorKey ? omit(newErrors, ["label"]) : newErrors;

    setErrors(newErrors);
  }, [isFormDirty, isLabelAlreadyExist, setErrors, errors, t]);

  useEffect(() => {
    isFormDirty ? dispatch(setEditSavedOrderStatus()) : dispatch(resetSavedOrderStatus());
  }, [dispatch, isFormDirty]);

  useEffect(() => {
    return () => dispatch(resetSavedOrderStatus());
  }, [dispatch]);

  return (
    <FormikProvider value={editSavedOrderForm}>
      <ModalLayout
        contentSection={
          <SetDeliveryFormLayout
            pageHeaderSection={
              <TextInput
                id="label"
                name="label"
                className="col-12 col-lg-7 px-0 pr-md-2"
                label={t("common.forms.nameLabel")}
                placeholder={t("common.forms.namePlaceholder")}
                onChange={debouncedChangeName}
              />
            }
            dateFormSection={<OrderTimeForm />}
            pickUpFormSection={<PickUpForm />}
            dropOffFormsSection={
              pick_up.location && drops.map(({ reference }) => <DropOffForm key={reference} reference={reference} />)
            }
          />
        }
        modalControls={
          <>
            <PrimaryButton
              id="submit-saved-order-form-btn"
              className="w-100"
              label={t("common.buttons.saveChangesBtn")}
              onClick={submitForm}
              loading={isSubmitting}
              disabled={!isFormDirty || !isValid}
            />

            <TextButton
              id="reset-saved-order-form-btn"
              className="mt-4 py-0"
              label={t("common.buttons.resetBtn")}
              onClick={openResetModal}
              disabled={!isFormDirty}
            />
          </>
        }
        modals={isResetModalOpen && <LibraryConfirmResetModal onConfirm={resetForm} onClose={closeResetModal} />}
      />
    </FormikProvider>
  );
};

OrderSettingsForm.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default OrderSettingsForm;
