import AuthPageLayout from "library/Layouts/PageLayout/AuthPageLayout";
import SEO from "library/Molecules/SEO";
import AuthFormFooter from "pages/Login/components/AuthFormFooter/AuthFormFooter";
import AuthFormHeader from "pages/Login/components/AuthFormHeader/AuthFormHeader";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import RegisterForm from "./components/RegisterForm/RegisterForm";

const Register = () => {
  const { t } = useTranslation();

  return (
    <AuthPageLayout
      titleSection={t("register.pageTitle")}
      headerSection={<AuthFormHeader />}
      formDescriptionSection={
        <Trans
          i18nKey="register.pageDescription"
          components={{
            lnk: <Link to="/login" className="pl-1 text-color-primary text-fw-semibold" data-cy="login-link" />,
          }}
        />
      }
      footerSection={<AuthFormFooter />}
      data-cy="register-form"
    >
      <SEO title={t("seo.helmetTitle", { name: t("seo.pages.register") })} />

      <RegisterForm />
    </AuthPageLayout>
  );
};

export default Register;
