import { first } from "lodash";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { retrieveContact } from "store/features/contact.store";
import { retrieveContactLocalization } from "store/features/localization.store";
import { prettifyAddressComponents } from "../utils/helper";

const useAutocomplete = () => {
  const { t, i18n } = useTranslation();
  const contact = useSelector(retrieveContact);
  const localization = useSelector(retrieveContactLocalization);

  const autocompleteSessionToken = useMemo(() => {
    return window.google ? new window.google.maps.places.AutocompleteSessionToken() : null;
  }, [window.google]);

  const placesAutocompleteService = useMemo(() => {
    return window.google ? new window.google.maps.places.AutocompleteService() : null;
  }, [window.google]);
  const placeService = useMemo(() => {
    return window.google ? new window.google.maps.places.PlacesService(document.createElement("div")) : null;
  }, [window.google]);
  const geocodeService = useMemo(() => (window.google ? new window.google.maps.Geocoder() : null), [window.google]);

  const language = useMemo(() => contact?.locale || i18n.language, [contact?.locale, i18n.language]);

  const onSearchAddressByTerm = useCallback(
    (searchTerm) => {
      if (!window.google || !localization) return new Promise();

      let bounds = null;
      if (localization.bounds) {
        bounds = new window.google.maps.LatLngBounds(
          new window.google.maps.LatLng(localization.bounds.southWest[0], localization.bounds.southWest[1]),
          new window.google.maps.LatLng(localization.bounds.northEast[0], localization.bounds.northEast[1])
        );
      }

      return new Promise((resolve) => {
        placesAutocompleteService?.getPlacePredictions(
          {
            ...(autocompleteSessionToken ? { sessionToken: autocompleteSessionToken } : {}),
            locationRestriction: bounds,
            input: searchTerm,
            language,
          },
          (res, status) => {
            if (status !== "OK") resolve([]);
            else resolve(res.map(({ place_id, description }) => ({ id: place_id, label: description })));
          }
        );
      });
    },
    [autocompleteSessionToken, placesAutocompleteService, localization, language, window.google]
  );

  const onSearchAddressByCoordinates = useCallback(
    (coordinates) => {
      if (!window.google) return new Promise();

      return new Promise((resolve) => {
        geocodeService.geocode({ location: coordinates }, (res, status) => {
          if (status !== "OK") {
            console.warn("something went wrong");
            return;
          }
          if (!res.length) resolve({ geolocation: [coordinates.lat, coordinates.lng] });

          const { city, country, secondLineAddress, buildingOrFlatNumber, postcode } = prettifyAddressComponents(
            first(res).address_components
          );

          if (!city && !i18n.exists(`utils.cities.${contact.city}`)) {
            resolve({ geolocation: [coordinates.lat, coordinates.lng] });
          }
          if (!country && !i18n.exists(`utils.countries.${localization.country_code}`)) {
            resolve({ geolocation: [coordinates.lat, coordinates.lng] });
          }

          resolve({
            postcode,
            geolocation: [coordinates.lat, coordinates.lng],
            location: `${secondLineAddress} ${buildingOrFlatNumber} ${city || t(`utils.cities.${contact.city}`)}`,
            city: city || t(`utils.cities.${contact.city}`),
            country: country || t(`utils.countries.${localization.country_code}`),
            secondLineAddress,
            buildingOrFlatNumber,
          });
        });
      });
    },
    [contact?.city, geocodeService, i18n, localization?.country_code, t, window.google]
  );

  const onGetPlaceDetails = useCallback(
    (addressId) => {
      if (!window.google) return new Promise();

      return new Promise((resolve) => {
        placeService?.getDetails({ placeId: addressId, language }, (res, status) => {
          if (status !== "OK") {
            console.warn("something went wrong");
            return;
          }

          if (!res.geometry?.location?.lat() || !res.geometry?.location?.lng()) resolve({});

          const { city, country, secondLineAddress, buildingOrFlatNumber, postcode } = prettifyAddressComponents(
            res.address_components
          );

          if (!city && !i18n.exists(`utils.cities.${contact.city}`)) resolve({});
          if (!country && !i18n.exists(`utils.countries.${localization.country_code}`)) resolve({});

          resolve({
            postcode,
            geolocation: [res.geometry?.location?.lat(), res.geometry?.location?.lng()],
            location: `${secondLineAddress} ${buildingOrFlatNumber} ${city || t(`utils.cities.${contact.city}`)}`,
            city: city || t(`utils.cities.${contact.city}`),
            country: country || t(`utils.countries.${localization.country_code}`),
            secondLineAddress,
            buildingOrFlatNumber,
          });
        });
      });
    },
    [placeService, language, i18n, contact?.city, localization?.country_code, t, window.google]
  );

  return window.google
    ? { onSearchAddressByTerm, onSearchAddressByCoordinates, onGetPlaceDetails }
    : { onSearchAddressByTerm: () => {}, onSearchAddressByCoordinates: () => {}, onGetPlaceDetails: () => {} };
};

export default useAutocomplete;
