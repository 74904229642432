import { addDays, differenceInCalendarDays, isEqual, isSameDay, isWithinInterval, subDays } from "date-fns";
import { useFormikContext } from "formik";
import useDidUpdate from "hooks/useDidUpdate";
import useToast from "hooks/useToast";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TOAST_TYPES } from "../../../constants";
import { generateDropTimeOptions, generatePickUpTimeOptions } from "../utils/helper";

const useTimeForm = (withoutPastLimit = false) => {
  const { t } = useTranslation();
  const onShowToast = useToast();

  const {
    values: { startDate, endDate, isExpress },
    setFieldValue,
  } = useFormikContext();

  const timePickUpOptions = useMemo(() => {
    return generatePickUpTimeOptions({ startDate, isExpress, withoutPastLimit });
  }, [startDate, isExpress, withoutPastLimit]);

  const timeToDropOffOptions = useMemo(() => {
    return generateDropTimeOptions({ startDate, endDate, isExpress, withoutPastLimit });
  }, [startDate, endDate, isExpress, withoutPastLimit]);

  const onToggleIsExpress = useCallback(
    (newValue) => {
      setFieldValue("isExpress", newValue);

      if (newValue || !startDate || !endDate) return;

      if (startDate.getHours() >= 19) {
        isSameDay(startDate, endDate) && setFieldValue("endDate", new Date(addDays(endDate, 1)));
      } else {
        !isSameDay(startDate, endDate) && setFieldValue("endDate", new Date(subDays(endDate, 1)));
      }
    },
    [startDate, endDate, setFieldValue]
  );

  const onChangePickUpAt = useCallback(
    (_, newStartDate) => {
      newStartDate = new Date(newStartDate);

      setFieldValue("startDate", newStartDate);

      let newEndDate = endDate;
      const lastEndTimeOption = new Date(timeToDropOffOptions[timeToDropOffOptions.length - 1].value);

      if (!isExpress) {
        if (newStartDate.getHours() >= 19) {
          isSameDay(newStartDate, newEndDate) && (newEndDate = new Date(addDays(lastEndTimeOption, 1)));
        } else {
          !isSameDay(newStartDate, newEndDate) && (newEndDate = new Date(subDays(lastEndTimeOption, 1)));
        }
      }

      if (isEqual(newEndDate, endDate)) return;
      setFieldValue("endDate", new Date(newEndDate));
    },
    [endDate, timeToDropOffOptions, isExpress, setFieldValue]
  );

  const onChangeDropOffAt = useCallback((_, newValue) => setFieldValue("endDate", new Date(newValue)), [setFieldValue]);

  useEffect(() => {
    if (!endDate || !timeToDropOffOptions.length) return;

    const firstDropOffOption = new Date(timeToDropOffOptions[0].value);
    const lastDropOffOption = new Date(timeToDropOffOptions[timeToDropOffOptions.length - 1].value);

    if (isWithinInterval(endDate, { start: firstDropOffOption, end: lastDropOffOption })) return;

    onShowToast(t("common.forms.dropOffTimeToast"), { type: TOAST_TYPES.WARNING, timeout: 2000 });
    setFieldValue("endDate", new Date(timeToDropOffOptions[timeToDropOffOptions.length - 1].value));
  }, [endDate, timeToDropOffOptions, setFieldValue, onShowToast]);

  useEffect(() => {
    if (!startDate || !timePickUpOptions.length) return;

    const firstPickUpOption = new Date(timePickUpOptions[0].value);
    const lastPickUpOption = new Date(timePickUpOptions[timePickUpOptions.length - 1].value);

    if (isWithinInterval(startDate, { start: firstPickUpOption, end: lastPickUpOption })) return;

    onShowToast(t("common.forms.pickUpTimeToast"), { type: TOAST_TYPES.WARNING, timeout: 2000 });
    setFieldValue("startDate", new Date(timePickUpOptions[0].value));
  }, [startDate, timePickUpOptions, onShowToast, setFieldValue]);

  useDidUpdate(
    (prev, curr) => {
      if (
        !!prev.endDate &&
        !!curr.endDate &&
        differenceInCalendarDays(new Date(prev.endDate), new Date(curr.endDate))
      ) {
        onShowToast(t("common.forms.dropOffTimeToast"), { type: TOAST_TYPES.WARNING, timeout: 2000 });
      }
    },
    { endDate, onShowToast }
  );

  return [
    { timePickUpOptions, timeToDropOffOptions },
    { onToggleIsExpress, onChangePickUpAt, onChangeDropOffAt },
  ];
};

export default useTimeForm;
