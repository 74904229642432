import "utility/validation";
import * as yup from "yup";

export default yup.object().shape({
  firstName: yup.string().required("common.forms.firstNameRequiredError"),
  lastName: yup.string().required("common.forms.lastNameRequiredError"),
  password: yup
    .string()
    .min(8, "register.forms.registerForm.passwordLengthError")
    .matches(/.*[A-Z]/, "register.forms.registerForm.passwordUppercaseError")
    .matches(/.*[a-z]/, "register.forms.registerForm.passwordLowercaseError")
    .matches(/.*[0-9]/, "register.forms.registerForm.passwordNumberError")
    .matches(/.*[$&+,:;=?@#|'<>.^*()%!-]/, "register.forms.registerForm.passwordSpecialCharacterError")
    .required("register.forms.registerForm.passwordRequiredError"),
  passwordConfirmation: yup
    .string()
    .required("register.forms.registerForm.confirmPasswordRequiredError")
    .test("match", "register.forms.registerForm.confirmPasswordMatchError", function match(passwordConfirmation) {
      return passwordConfirmation === this.options.context.password;
    }),
  email: yup.string().email("common.forms.emailFormatError").required("common.forms.emailRequiredError"),
  invoiceEmail: yup.string().email("common.forms.emailFormatError").required("common.forms.invoiceEmailRequiredError"),
  phone: yup.string().required("common.forms.phoneRequiredError").phone("common.forms.phoneInvalidError"),
  city: yup.string().required("common.forms.cityRequiredError"),
  company: yup.string().required("common.forms.companyNameRequiredError"),
  companyVat: yup.string(),
  companyAddress: yup.string().required("common.forms.companyAddressRequiredError"),
  companyBillingAddress: yup.string(),
  acceptTerms: yup.boolean().oneOf([true], "register.forms.registerForm.acceptPolicyError"),
});
