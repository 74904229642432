import BoxLayout from "library/Layouts/BoxLayout/BoxLayout";
import MiscDetailsLayout from "library/Layouts/DetailsLayout/MiscDetailsLayout/MiscDetailsLayout";
import { useTranslation } from "react-i18next";
import { InvoiceLineType } from "types/models/Invoice";
import { formatFullDate } from "utility/date-fns";
import { convertTimestampToDate, formatPrice } from "utility/helpers/general";

const InvoiceLine = ({ line }) => {
  const { t } = useTranslation();

  return (
    <BoxLayout className="mb-4 py-4" data-cy={`line-item-${line.id}`}>
      <div className="d-flex flex-nowrap text-fs-14 text-fw-semibold text-truncate px-4">
        {t("invoices.invoice.line.id", { id: line.id })}
      </div>

      <div className="d-flex flex-wrap col-12 mt-4 px-0">
        <MiscDetailsLayout
          className="col-xl-8 col-12"
          titleSection={t("invoices.invoice.line.description")}
          contentSection={<span className="text-wrap">{line.description}</span>}
        />

        <MiscDetailsLayout
          className="col-xl-4 col-12"
          titleSection={t("utils.timestamps.serviceDate")}
          contentSection={formatFullDate(convertTimestampToDate(line.service_date))}
        />
      </div>

      <div className="col-12 px-4 text-right text-fs-14 text-truncate">
        {t("utils.pricing.vat")}: {line.taxCodeRef}
      </div>

      <div className="col-12 px-4 text-right text-fs-14 text-truncate text-fw-semibold">
        <span className="pr-2">{t("utils.pricing.price")}:</span>

        {t("utils.pricing.exclVAT", "{{price}} excl. VAT", {
          price: formatPrice(line.price, line.currency ?? ""),
        })}
      </div>
    </BoxLayout>
  );
};

InvoiceLine.propTypes = {
  line: InvoiceLineType.isRequired,
};

export default InvoiceLine;
