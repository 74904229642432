import { unwrapResult } from "@reduxjs/toolkit";
import { first, isEmpty, isEqual } from "lodash";
import { createContext, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { retrieveContact } from "store/features/contact.store";
import { saveOrderRequest } from "store/requests/library.requests";
import { calculateOrderPriceRequest, createNewOrderRequest } from "store/requests/newOrder.requests";
import { NEW_ORDER_FORM_STEPS, REPEAT_ORDER_TYPES } from "../../constants";

export const NewOrderContext = createContext();

const initialState = { order: {}, customer: {}, price: {}, references: [] };

export const NewOrderContextProvider = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const contact = useSelector(retrieveContact);

  const [newOrder, setNewOrder] = useState(initialState);

  const [step, setStep] = useState(NEW_ORDER_FORM_STEPS.SET_DELIVERY);
  const [dirty, setDirty] = useState(false);

  const setOrderDetails = useCallback((newDetails) => {
    setNewOrder((prev) => ({ ...prev, order: { ...newDetails } }));
  }, []);
  const setCustomerDetails = useCallback((newDetails) => {
    setNewOrder((prev) => ({ ...prev, customer: { ...newDetails } }));
  }, []);
  const setPriceDetails = useCallback((newDetails) => {
    setNewOrder((prev) => ({ ...prev, price: { ...newDetails } }));
  }, []);
  const setReferences = useCallback((references) => setNewOrder((prev) => ({ ...prev, references })), []);

  const handleSetDeliveryStep = useCallback(() => setStep(NEW_ORDER_FORM_STEPS.SET_DELIVERY), []);
  const handleSetCalculateStep = useCallback(() => setStep(NEW_ORDER_FORM_STEPS.CALCULATE), []);
  const handleSetBookingStep = useCallback(() => setStep(NEW_ORDER_FORM_STEPS.BOOKING), []);
  const handleSetFinalStep = useCallback(() => setStep(NEW_ORDER_FORM_STEPS.FINAL), []);

  const onCalculatePrice = useCallback(
    async (values) => {
      if (isEqual(values, newOrder.order)) handleSetCalculateStep();
      else {
        const { startDate, pick_up, drops, isExpress } = values;

        const res = await dispatch(calculateOrderPriceRequest({ startDate, pick_up, drops, isExpress })).then(
          unwrapResult
        );
        setPriceDetails(res.data);
        setOrderDetails(values);
        handleSetCalculateStep();
      }
    },
    [newOrder.order, handleSetCalculateStep, dispatch, setPriceDetails, setOrderDetails]
  );

  const resetNewOrderForm = useCallback(() => {
    setNewOrder(initialState);
    handleSetDeliveryStep();
    setDirty(false);

    if (!isEmpty(contact)) setCustomerDetails(contact);
  }, [handleSetDeliveryStep, contact, setCustomerDetails]);

  const onSendNewOrder = useCallback(
    async (customer) => {
      const {
        order: { repeat_settings, ...order },
      } = newOrder;

      setCustomerDetails(customer);

      const res = await dispatch(createNewOrderRequest({ order: { ...order, repeat_settings }, customer })).then(
        unwrapResult
      );

      if (repeat_settings.type === REPEAT_ORDER_TYPES.EXACT_DATES) {
        setReferences(res.data.map(({ id, reference, delivery_date }) => ({ id, reference, date: delivery_date })));
        return { id: first(res.data).id, reference: first(res.data).reference };
      }

      setReferences([{ id: res.data.id, reference: res.data.reference }]);
      return { id: res.data.id, reference: res.data.reference };
    },
    [newOrder, setCustomerDetails, dispatch, setReferences]
  );

  const submitNewOrderForm = useCallback(
    async (customer) => {
      await onSendNewOrder(customer);
      handleSetFinalStep();
    },
    [handleSetFinalStep, onSendNewOrder]
  );
  const submitAndSaveNewOrderForm = useCallback(
    async (customer) => {
      const { id, reference } = await onSendNewOrder(customer);

      await dispatch(saveOrderRequest({ id, label: t("dashboard.order.copyOfOrderLabel", { reference }) }))
        .then(unwrapResult)
        .catch(() => {})
        .finally(() => handleSetFinalStep());
    },
    [dispatch, handleSetFinalStep, onSendNewOrder, t]
  );

  useEffect(() => {
    if (isEmpty(contact)) return;
    setCustomerDetails(contact);
  }, [setCustomerDetails, contact]);

  return (
    <NewOrderContext.Provider
      value={[
        newOrder,
        {
          step,
          handleSetDeliveryStep,
          handleSetCalculateStep,
          handleSetBookingStep,

          resetForm: resetNewOrderForm,

          dirty,
          setDirty,

          submitForm: submitNewOrderForm,
          submitAndSaveForm: submitAndSaveNewOrderForm,
        },
        {
          setOrderDetails,
          onCalculatePrice,
        },
      ]}
      {...props}
    />
  );
};
