import DeleteOrderIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOrderIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import useOpenClose from "hooks/useOpenClose";
import IconButton from "library/Atoms/Button/IconButton";
import LibraryConfirmDeleteModal from "pages/Library/modals/LibraryConfirmDeleteModal/LibraryConfirmDeleteModal";
import OrderSettingsModal from "pages/Library/modals/LibraryItemSettingsModal/OrderSettingsModal/OrderSettingsModal";
import { useSelector } from "react-redux";
import { retrieveSelectedSavedOrder } from "store/features/library.store";
import OrderSettingsForm from "../../OrderSettingsForm/OrderSettingsForm";

const OrderControls = () => {
  const { id } = useSelector(retrieveSelectedSavedOrder);

  const [isConfirmDeleteModalOpen, openConfirmDeleteModal, closeConfirmDeleteModal] = useOpenClose(false);
  const [isEditOrderModalOpen, openEditOrderModal, closeEditOrderModal] = useOpenClose(false);

  return (
    <>
      <div className="d-flex flex-column gap-3">
        <IconButton id={`edit-order-${id}-btn-mobile`} onClick={openEditOrderModal} color="yellow" isRounded>
          <EditOrderIcon />
        </IconButton>

        <IconButton id={`delete-order-${id}-btn-mobile`} onClick={openConfirmDeleteModal} color="error" isRounded>
          <DeleteOrderIcon />
        </IconButton>
      </div>

      {isConfirmDeleteModalOpen && <LibraryConfirmDeleteModal onClose={closeConfirmDeleteModal} />}

      {isEditOrderModalOpen && (
        <OrderSettingsModal onClose={closeEditOrderModal}>
          <OrderSettingsForm onClose={closeEditOrderModal} />
        </OrderSettingsModal>
      )}
    </>
  );
};

export default OrderControls;
