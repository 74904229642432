import { cx } from "@emotion/css";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import useDeviceType from "hooks/useDeviceType";
import PrimaryButton from "library/Atoms/Button/PrimaryButton";
import Chip from "library/Atoms/Chip";
import MiscDetailsLayout from "library/Layouts/DetailsLayout/MiscDetailsLayout/MiscDetailsLayout";
import { isUndefined } from "lodash";
import OrderBriefInfo from "pages/Dashboard/components/OrdersList/OrderItem/OrderBriefInfo/OrderBriefInfo";
import useOrders from "pages/Dashboard/hooks/useOrders";
import OrderDetailsLayout from "pages/Dashboard/layouts/OrderDetailsLayout/OrderDetailsLayout";
import { PropTypes } from "prop-types";
import { useEffect, useMemo, useRef } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { fetchOrderPositionRequest } from "store/requests/order.requests";
import { formatFullDate } from "utility/date-fns";
import { convertTimestampToDate, formatPrice } from "utility/helpers/general";
import { calculatePackagesAmount, getPriceDiscount } from "utility/helpers/orders";
import { CLAIMED_STATUS } from "../../../../constants";
import CancelOrderBanner from "./CancelOrderBanner/CancelOrderBanner";
import DropDetails from "./DropDetails/DropDetails";
import styles from "./OrderDetails.styles";
import SaveOrderButton from "./SaveOrderButton/SaveOrderButton";
import StatusBanner from "./StatusBanner/StatusBanner";

const OrderDetails = ({ mapOpen, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isMobile } = useDeviceType();

  const [{ selectedOrder }, , { isOrderSelected }] = useOrders();

  const intervalRef = useRef();

  const shouldShowDiscount = useMemo(() => {
    if (!isOrderSelected) return false;
    const { discount } = getPriceDiscount({ ...selectedOrder.price, price: selectedOrder.price.total });
    return discount > 0;
  }, [isOrderSelected, selectedOrder]);

  useEffect(() => {
    if (!isOrderSelected || selectedOrder.status !== CLAIMED_STATUS) return;

    // if not testing env
    if (isUndefined(window.Cypress)) {
      intervalRef.current = setInterval(() => dispatch(fetchOrderPositionRequest(selectedOrder.id)), 60000);
    }

    dispatch(fetchOrderPositionRequest(selectedOrder.id));
  }, [dispatch, selectedOrder.id, selectedOrder.status, isOrderSelected]);

  useEffect(() => {
    if (!isOrderSelected || selectedOrder.status !== CLAIMED_STATUS) clearInterval(intervalRef.current);
  }, [isOrderSelected, selectedOrder.status]);

  useEffect(() => {
    return () => intervalRef.current && clearInterval(intervalRef.current);
  }, []);

  if (!isOrderSelected) return null;
  return (
    <OrderDetailsLayout
      statusBarSection={<StatusBanner id={selectedOrder.reference} status={selectedOrder.status} />}
      briefInfoSection={
        <>
          <OrderBriefInfo
            reference={selectedOrder.reference}
            startDate={selectedOrder.startDate}
            endDate={selectedOrder.endDate}
            packages={calculatePackagesAmount(selectedOrder.drops)}
          />

          {selectedOrder.isExpress && (
            <Chip
              label={t("common.entities.order.express")}
              icon={<AccessTimeIcon />}
              data-cy={`express-${selectedOrder.reference}-order-details-chip`}
            />
          )}
        </>
      }
      extraInfoSection={
        <>
          <MiscDetailsLayout
            className="col-sm-4 col-6 my-3"
            titleSection={t("utils.timestamps.createdAt")}
            contentSection={formatFullDate(convertTimestampToDate(selectedOrder.created_at))}
          />

          <MiscDetailsLayout
            className="col-sm-4 col-6 my-3"
            titleSection={t("utils.timestamps.updatedAt")}
            contentSection={formatFullDate(convertTimestampToDate(selectedOrder.updated_at))}
          />

          <MiscDetailsLayout
            className="col-sm-4 col-6 my-3"
            titleSection={t("utils.timestamps.scheduledFor")}
            contentSection={formatFullDate(convertTimestampToDate(selectedOrder.delivery_date))}
          />
        </>
      }
      dropsListSection={selectedOrder.drops?.map(({ reference: dropRef, ...drop }) => (
        <DropDetails
          key={dropRef}
          pick_up={selectedOrder.pick_up}
          drop={{ ...drop, reference: dropRef, currency: selectedOrder.price.currency }}
          rider={selectedOrder.rider}
        />
      ))}
      priceSection={
        <>
          <span className="pr-2">{t("utils.pricing.totalPrice")}:</span>

          <Trans
            i18nKey="utils.pricing.inclVAT"
            context={shouldShowDiscount ? "discount" : ""}
            tOptions={{
              price: formatPrice(selectedOrder.price.total, selectedOrder.price.currency),
              discount: formatPrice(selectedOrder.price.starting_price, selectedOrder.price.currency),
            }}
            components={{
              span: <span data-cy={`order-${selectedOrder.reference}-price`} />,
              del: <del className="text-fs-12 text-color-error" />,
              span_discount: <span data-cy={`order-${selectedOrder.reference}-discount`} />,
            }}
            defaults={
              shouldShowDiscount
                ? "<span>{{price}}</span> <del>(<span_discount>{{discount}}</span_discount>)</del> inc. VAT"
                : "<span>{{price}}</span> inc. VAT"
            }
          />
        </>
      }
      controlsSection={
        <>
          {!isMobile && <SaveOrderButton className={styles.closeBtn(isMobile)} />}

          <PrimaryButton
            id="back-to-orders-btn"
            className={cx(styles.closeBtn(isMobile), "w-100")}
            contentClassName="text-truncate text-fs-12"
            label={t("common.buttons.backToBtn", { context: mapOpen && isMobile ? "map" : "orders" })}
            onClick={onClose}
            data-cy={mapOpen && isMobile ? "back-to-map" : "back-to-orders"}
          />
        </>
      }
      cancelOrderSection={<CancelOrderBanner />}
    />
  );
};

OrderDetails.propTypes = {
  mapOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default OrderDetails;
