import { addDays, setHours, setMinutes } from "date-fns";
import { useFormikContext } from "formik";
import Toggler from "library/Atoms/Switch/Toggler";
import Tooltip from "library/Atoms/Tooltip";
import DateMultiplePicker from "library/Molecules/DatePicker/DateMultiplePicker";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { DEFAULT_REPEAT_ORDER_SETTINGS, REPEAT_ORDER_TYPES } from "../../../../../../constants";

const RepeatSettingsForm = () => {
  const { t } = useTranslation();

  const {
    values: { startDate, repeat_settings },
    setFieldValue,
  } = useFormikContext();

  const onToggleIsRepeated = useCallback(
    (isRepeated) =>
      setFieldValue(
        "repeat_settings",
        isRepeated ? { type: REPEAT_ORDER_TYPES.EXACT_DATES, dates: [] } : DEFAULT_REPEAT_ORDER_SETTINGS
      ),
    [setFieldValue]
  );

  const onChangeRepeatAt = useCallback(
    (dates) => setFieldValue("repeat_settings", { type: REPEAT_ORDER_TYPES.EXACT_DATES, dates }),
    [setFieldValue]
  );

  if (!startDate) return null;
  return (
    <>
      <Toggler
        id="isRepeated"
        className="mt-md-4 mt-5"
        name="isRepeated"
        label={
          <>
            <span>{t("newOrder.forms.repeatSettingsForm.repeatOrderLabel")}</span>
            <Tooltip>{t("newOrder.forms.repeatSettingsForm.repeatOrderTooltip")}</Tooltip>
          </>
        }
        value={repeat_settings.type !== REPEAT_ORDER_TYPES.NONE}
        onChange={onToggleIsRepeated}
      />

      {repeat_settings.type !== REPEAT_ORDER_TYPES.NONE && (
        <div className="d-flex flex-row flex-wrap mt-4">
          <DateMultiplePicker
            id="datesToRepeat"
            className="col-12 col-md-4 px-0 pr-md-2"
            name="repeat_settings.dates"
            placeholder={t("newOrder.forms.repeatSettingsForm.repeatOrderPlaceholder")}
            onChange={onChangeRepeatAt}
            minDate={new Date(addDays(setMinutes(setHours(startDate, 0), 0), 1))}
          />
        </div>
      )}
    </>
  );
};

export default RepeatSettingsForm;
