import { Alert as MuiAlert } from "@mui/material";
import { PropTypes } from "prop-types";

const Alert = ({ type, message, onClose }) => (
  <MuiAlert
    className="d-flex align-items-center"
    variant="outlined"
    severity={type}
    onClose={onClose}
    componentsProps={{ closeButton: { "data-cy": `${type}-toast-close-btn` } }}
    data-cy={`${type}-toast`}
  >
    <span className="text-fs-12">{message}</span>
  </MuiAlert>
);

Alert.defaultProps = {
  type: "error",
  message: "",
  onClose: () => {},
};

Alert.propTypes = {
  type: PropTypes.oneOf(["success", "info", "warning", "error"]),
  message: PropTypes.string,
  onClose: PropTypes.func,
};

export default Alert;
