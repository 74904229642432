import RejectedIcon from "@mui/icons-material/Block";
import DeliveredIcon from "@mui/icons-material/CheckRounded";
import FailedIcon from "@mui/icons-material/CloseRounded";
import ReturnedIcon from "@mui/icons-material/KeyboardReturn";
import ClaimedIcon from "@mui/icons-material/MoreHorizRounded";
import PendingIcon from "@mui/icons-material/PriorityHighRounded";
import CanceledIcon from "@mui/icons-material/RemoveCircleOutline";
import AcceptedIcon from "@mui/icons-material/SubjectRounded";
import CanceledMarkerIcon from "assets/svg/markers/canceled-marker.svg";
import DeliveredMarkerIcon from "assets/svg/markers/delivered-marker.svg";
import FailedMarkerIcon from "assets/svg/markers/failed-marker.svg";
import ClaimedMarkerIcon from "assets/svg/markers/in-progress-marker.svg";
import PendingMarkerIcon from "assets/svg/markers/pending-marker.svg";
import RejectedMakerIcon from "assets/svg/markers/rejected-marker.svg";
import ReturnedMarkerIcon from "assets/svg/markers/returned-marker.svg";
import AcceptedMarkerIcon from "assets/svg/markers/scheduled-marker.svg";
import {
  ACCEPTED_STATUS,
  CANCELED_STATUS,
  CLAIMED_STATUS,
  DELIVERED_STATUS,
  FAILED_STATUS,
  REJECTED_STATUS,
  RETURNED_STATUS,
} from "../../../constants";

export const getOrderStatusMarkerIcon = (status) => {
  switch (status) {
    case REJECTED_STATUS:
      return RejectedMakerIcon;
    case CANCELED_STATUS:
      return CanceledMarkerIcon;
    case ACCEPTED_STATUS:
      return AcceptedMarkerIcon;
    case CLAIMED_STATUS:
      return ClaimedMarkerIcon;
    case DELIVERED_STATUS:
      return DeliveredMarkerIcon;
    case FAILED_STATUS:
      return FailedMarkerIcon;
    case RETURNED_STATUS:
      return ReturnedMarkerIcon;
    default:
      return PendingMarkerIcon;
  }
};

export const getOrderStatusIcon = (status) => {
  switch (status) {
    case REJECTED_STATUS:
      return RejectedIcon;
    case CANCELED_STATUS:
      return CanceledIcon;
    case ACCEPTED_STATUS:
      return AcceptedIcon;
    case CLAIMED_STATUS:
      return ClaimedIcon;
    case DELIVERED_STATUS:
      return DeliveredIcon;
    case FAILED_STATUS:
      return FailedIcon;
    case RETURNED_STATUS:
      return ReturnedIcon;
    default:
      return PendingIcon;
  }
};

export const getOrderStatusColor = (status) => {
  switch (status) {
    case ACCEPTED_STATUS:
      return "yellow";
    case CLAIMED_STATUS:
      return "warning";
    case DELIVERED_STATUS:
      return "primary";
    case FAILED_STATUS:
      return "error";
    case RETURNED_STATUS:
      return "error";
    default:
      return "info";
  }
};
