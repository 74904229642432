import { cx } from "@emotion/css";
import { unwrapResult } from "@reduxjs/toolkit";
import { FormikProvider, useFormik } from "formik";
import useDebounce from "hooks/useDebounce";
import PrimaryButton from "library/Atoms/Button/PrimaryButton";
import TextInput from "library/Atoms/Input/TextInput";
import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { logInRequest } from "store/requests/contact.requests";
import styles from "./LoginForm.styles";
import LoginFormValidation from "./LoginForm.validation";

const LoginForm = ({ onForgotPassword }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const loginForm = useFormik({
    initialValues: { email: "", password: "" },
    onSubmit: (values, { setSubmitting, setFieldError }) => {
      dispatch(logInRequest(values))
        .then(unwrapResult)
        .catch((error) => {
          if (error.code === "auth/wrong-password") {
            setFieldError("password", t("login.forms.loginForm.passwordIncorrectError"));
          }
          if (error.code === "auth/user-not-found") {
            setFieldError("email", t("login.forms.loginForm.emailIncorrectError"));
          }
        })
        .finally(() => setSubmitting(false));
    },
    validationSchema: LoginFormValidation,
    validateOnBlur: true,
  });
  const { submitForm, isValid, isSubmitting, dirty: isFormDirty, setFieldValue } = loginForm;

  const debouncedSetFieldValue = useDebounce(setFieldValue);

  return (
    <>
      <input className={cx(styles.hiddenInput, "overflow-hidden bg-transparent p-0 border-0")} name="email" />
      <input className={cx(styles.hiddenInput, "overflow-hidden bg-transparent p-0 border-0")} type="password" />

      <FormikProvider value={loginForm}>
        <TextInput id="email" name="email" label={t("common.forms.emailLabel")} onChange={debouncedSetFieldValue} />

        <TextInput
          id="password"
          name="password"
          label={t("common.forms.passwordLabel")}
          onChange={debouncedSetFieldValue}
          type="password"
        />

        <Link
          id="reset-password-link"
          className="my-3 text-fs-12 text-right text-color-primary text-fw-semibold"
          onClick={onForgotPassword}
        >
          {t("login.forms.loginForm.forgonPasswordBtn")}
        </Link>

        <PrimaryButton
          id="login-btn"
          className={cx(styles.btn, "col-12 mt-3 px-0 w-100")}
          label={t("login.forms.loginForm.loginBtn")}
          onClick={submitForm}
          loading={isSubmitting}
          disabled={!isValid || isSubmitting || !isFormDirty}
        />
      </FormikProvider>
    </>
  );
};

LoginForm.propTypes = {
  onForgotPassword: PropTypes.func.isRequired,
};

export default LoginForm;
