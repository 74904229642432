import { cx } from "@emotion/css";
import { CloseRounded as CloseRoundedIcon, Today as TodayIcon } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider, PickersDay } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { addDays, isPast, isWeekend, setHours } from "date-fns";
import useOpenClose from "hooks/useOpenClose";
import DefaultInput from "library/Atoms/Input/DefaultInput";
import InputLayout from "library/Layouts/InputLayout/DefaultInputLayout";
import { omit } from "lodash";
import { PropTypes } from "prop-types";
import { useCallback, useMemo } from "react";
import { formatDate } from "utility/date-fns";
import { getDateFnsLocale } from "utility/helpers/locales";
import { WORKING_HOURS_CONFIG } from "../../../../constants";
import styles from "./styles";

const DatePickerInput = ({
  id,
  className,
  name,
  value,
  onClick,
  onClear,
  placeholder,
  inputProps,
  clearable,
  ...restParams
}) => {
  const [isInputHovered, setInputHovered, unsetInputHovered] = useOpenClose(false);

  return (
    <DefaultInput
      {...restParams}
      id={id}
      className={className}
      name={name}
      value={value}
      inputProps={{ ...omit(inputProps, ["value"]), className: "text-truncate", placeholder, readOnly: true }}
      onClick={onClick}
      onMouseEnter={setInputHovered}
      onMouseLeave={unsetInputHovered}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={{
        className: cx(styles.input, "pr-3"),
        endAdornment: (
          <div className="position-relative d-flex align-items-center">
            {clearable && (
              <IconButton id={`${id}-clear-indicator`} onClick={onClear}>
                <CloseRoundedIcon
                  className={cx(!isInputHovered && "d-none", "cursor-pointer text-color-grey400")}
                  fontSize="small"
                />
              </IconButton>
            )}

            <TodayIcon id={`${name}-openPickerBtn`} className="cursor-pointer" color="primary" fontSize="small" />
          </div>
        ),
      }}
    />
  );
};

DatePickerInput.defaultProps = {
  id: null,
  className: null,
  name: null,
  value: null,
  placeholder: null,
  onClear: () => {},
  clearable: false,
  inputProps: {},
};

DatePickerInput.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  onClear: PropTypes.func,
  placeholder: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  inputProps: PropTypes.object,
  clearable: PropTypes.bool,
};

const DatePickerDay = ({ className, date, onClick, ...restProps }) => {
  const handleClick = useCallback(() => onClick?.(date), [onClick, date]);

  return (
    <PickersDay
      key={date}
      className={cx(isWeekend(date) && !isPast(date) && styles.weekend, className)}
      onClick={handleClick}
      aria-label={formatDate(date)}
      {...restProps}
    />
  );
};

DatePickerDay.defaultProps = {
  className: null,
  onClick: null,
};

DatePickerDay.propTypes = {
  className: PropTypes.string,
  date: PropTypes.instanceOf(Date).isRequired,
  onClick: PropTypes.func,
};

export { DatePickerDay, DatePickerInput };

const DatePicker = ({ id, name, value, label, placeholder, onChange, helper, required }) => {
  const [isDatePickerOpen, setOpenDatePicker, setCloseDatePicker] = useOpenClose(false);

  const minDate = useMemo(() => {
    if (new Date().getHours() >= WORKING_HOURS_CONFIG.end) return new Date(addDays(setHours(new Date(), 0), 1));
    return new Date();
  }, []);

  const selectedDate = useMemo(() => (value ? formatDate(value) : ""), [value]);

  const handleClear = useCallback(() => onChange(null), [onChange]);

  return (
    <LocalizationProvider adapterLocale={getDateFnsLocale()} dateAdapter={AdapterDateFns}>
      <InputLayout name={name} label={label} helper={helper} required={required}>
        <DesktopDatePicker
          value={value}
          onChange={onChange}
          open={isDatePickerOpen}
          renderInput={({ inputProps, ...restParams }) => (
            <DatePickerInput
              {...restParams}
              id={id}
              name={name}
              value={selectedDate}
              placeholder={placeholder}
              onClick={setOpenDatePicker}
              onClear={handleClear}
              inputProps={inputProps}
              clearable={!!selectedDate}
            />
          )}
          renderDay={(date, _, params) => (
            <DatePickerDay date={date} className={cx(isWeekend(date) && !isPast(date) && styles.weekend)} {...params} />
          )}
          components={{ OpenPickerIcon: () => <TodayIcon id={`${name}-openPickerBtn`} fontSize="small" /> }}
          OpenPickerButtonProps={{ color: "primary" }}
          PopperProps={{
            "data-cy": `${id}-menu`,
          }}
          minDate={minDate}
          onClose={setCloseDatePicker}
          views={["day"]}
          leftArrowButtonText=""
          rightArrowButtonText=""
        />
      </InputLayout>
    </LocalizationProvider>
  );
};

DatePicker.defaultProps = {
  value: null,
  label: null,
  placeholder: null,
  helper: null,
  required: false,
};

DatePicker.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.instanceOf(Date),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  helper: PropTypes.node,
  required: PropTypes.bool,
};

export default DatePicker;
