import { cx } from "@emotion/css";
import useDeviceType from "hooks/useDeviceType";
import PrimaryButton from "library/Atoms/Button/PrimaryButton";
import SecondaryButton from "library/Atoms/Button/SecondaryButton";
import TextButton from "library/Atoms/Button/TextButton";
import { omit } from "lodash";
import { PropTypes } from "prop-types";
import commonStyles from "utility/ui/common";

const FormControls = ({ className, resetBtnOptions, submitBtnsOptions }) => {
  const { isMobile, isDesktop } = useDeviceType();

  return (
    <div
      className={cx(
        className,
        "d-flex flex-lg-row flex-column-reverse justify-content-center justify-content-sm-end align-items-center col-12 gap-3 px-0"
      )}
    >
      {isMobile ? (
        <TextButton className={cx(resetBtnOptions.className, "align-itself-center")} {...resetBtnOptions} />
      ) : (
        <SecondaryButton
          className={cx(
            resetBtnOptions.className,
            !isDesktop && commonStyles.controlBtnMobile,
            "col-12 col-sm-5 col-md-3"
          )}
          {...omit(resetBtnOptions, ["className"])}
        />
      )}

      {submitBtnsOptions.map((options) => (
        <PrimaryButton
          key={options.id}
          className={cx(options.className, !isDesktop && commonStyles.controlBtnMobile, "col-12 col-sm-5 col-md-3")}
          {...omit(options, [className])}
        />
      ))}
    </div>
  );
};

FormControls.defaultProps = {
  className: null,
};

FormControls.propTypes = {
  className: PropTypes.string,
  resetBtnOptions: PropTypes.shape({
    id: PropTypes.string,
    className: PropTypes.string,
    label: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    loading: PropTypes.func,
  }).isRequired,
  submitBtnsOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      className: PropTypes.string,
      label: PropTypes.string,
      onClick: PropTypes.func.isRequired,
      disabled: PropTypes.bool,
      loading: PropTypes.bool,
    })
  ).isRequired,
};

export default FormControls;
