import { cx } from "@emotion/css";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { isSameDay } from "date-fns";
import { useField } from "formik";
import useOpenClose from "hooks/useOpenClose";
import InputLayout from "library/Layouts/InputLayout/DefaultInputLayout";
import { size } from "lodash";
import { PropTypes } from "prop-types";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { formatDateFlat } from "utility/date-fns";
import { getDateFnsLocale } from "utility/helpers/locales";
import { DatePickerDay, DatePickerInput } from "../DatePicker";
import styles from "./styles";

const DateMultiplePicker = ({ id, className, name, label, placeholder, onChange, minDate, limit }) => {
  const { t } = useTranslation();

  const [{ value: dates = [] } = {}] = useField({ name });

  const [isPickerOpen, openPicker, closePicker] = useOpenClose(false);

  const inputValue = useMemo(() => {
    const value = dates
      .slice(0, 2)
      .map((date) => formatDateFlat(date))
      .join(", ");

    if (size(dates) > 2) return t("utils.ui.multiDatesPlaceholder", { dates: value });

    return value;
  }, [dates]);

  const handleChange = useCallback(
    (newDate) => {
      if (size(dates) >= limit) return;

      const selectedDayIndex = dates.findIndex((date) => isSameDay(newDate, date));
      if (selectedDayIndex !== -1) onChange([...dates.filter((_, index) => index !== selectedDayIndex)]);
      else onChange([...dates, newDate]);
    },
    [dates, onChange, limit]
  );
  const handleClear = useCallback(() => onChange([]), [onChange]);

  return (
    <LocalizationProvider adapterLocale={getDateFnsLocale()} dateAdapter={AdapterDateFns}>
      <InputLayout name={name} className={cx(className, "text-fw-semibold")} label={label}>
        <DesktopDatePicker
          open={isPickerOpen}
          value={minDate}
          onChange={() => {}}
          onClose={closePicker}
          renderDay={(date, _, params) => {
            const isToday = isSameDay(date, new Date());
            const isSelectedDay = dates.some((selectedDay) => isSameDay(date, selectedDay));

            return (
              <div key={date}>
                <DatePickerDay
                  {...params}
                  date={date}
                  className={cx(isToday && styles.today, isSelectedDay && "Mui-selected")}
                  onClick={handleChange}
                  selected={false}
                  disableHighlightToday
                />
              </div>
            );
          }}
          renderInput={({ inputProps, ...restParams }) => (
            <DatePickerInput
              {...restParams}
              id={id}
              className="col-12 px-0"
              value={inputValue}
              onClick={openPicker}
              onClear={handleClear}
              placeholder={placeholder}
              inputProps={inputProps}
              clearable={size(dates) > 0}
            />
          )}
          PopperProps={{
            "data-cy": `${id}-menu`,
          }}
          views={["day"]}
          minDate={minDate}
          closeOnSelect={false}
          leftArrowButtonText=""
          rightArrowButtonText=""
        />
      </InputLayout>
    </LocalizationProvider>
  );
};

DateMultiplePicker.defaultProps = {
  className: null,
  label: null,
  placeholder: "",
  minDate: new Date(),
  limit: 10,
};

DateMultiplePicker.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  minDate: PropTypes.instanceOf(Date),
  limit: PropTypes.number,
};

export default DateMultiplePicker;
