import { isSameDay, isWeekend } from "date-fns";
import { useFormikContext } from "formik";
import Select from "library/Atoms/Select";
import Toggler from "library/Atoms/Switch/Toggler";
import Tooltip from "library/Atoms/Tooltip";
import DatePicker from "library/Molecules/DatePicker/DatePicker";
import useTimeForm from "pages/NewOrder/hooks/useTimeForm";
import DateFormLayout from "pages/NewOrder/layouts/FormLayout/DateFormLayout/DateFormLayout";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { formatFullDate } from "utility/date-fns";
import { isPeakTime } from "utility/helpers/general";
import { generateTimePickerData } from "utility/helpers/orders";
import { DEFAULT_REPEAT_ORDER_SETTINGS, REPEAT_ORDER_TYPES } from "../../../../../constants";
import RepeatSettingsForm from "./RepeatSettingsForm/RepeatSettingsForm";

const DateForm = () => {
  const { t } = useTranslation();

  const {
    values: { startDate, endDate, isExpress, repeat_settings },
    setFieldValue,
  } = useFormikContext();

  const [{ timePickUpOptions, timeToDropOffOptions }, { onToggleIsExpress, onChangePickUpAt, onChangeDropOffAt }] =
    useTimeForm();

  const onChangeDate = useCallback(
    (newDate) => {
      if (!newDate) {
        setFieldValue("startDate", null);
        setFieldValue("endDate", null);
        setFieldValue("repeat_settings", DEFAULT_REPEAT_ORDER_SETTINGS);
      } else {
        const optionsForTimePicker = generateTimePickerData(newDate);
        setFieldValue("startDate", new Date(optionsForTimePicker[0].value));
        setFieldValue("endDate", new Date(optionsForTimePicker[optionsForTimePicker.length - 1].value));

        if (repeat_settings.type !== REPEAT_ORDER_TYPES.NONE) {
          setFieldValue("repeat_settings", { ...repeat_settings, dates: [] });
        }
      }
    },
    [setFieldValue, repeat_settings]
  );

  return (
    <DateFormLayout
      className="mt-3 pt-2"
      titleSection={t("newOrder.forms.dateForm.formTitle")}
      expressTogglerSection={
        <Toggler
          id="isExpress"
          name="isExpress"
          label={
            <>
              <span>{t("common.forms.expressDeliveryLabel")}</span>
              <Tooltip>{t("common.forms.expressDeliveryTooltip")}</Tooltip>
            </>
          }
          onChange={onToggleIsExpress}
        />
      }
      datePickerSection={
        <DatePicker
          id="deliveryDate"
          name="deliveryDate"
          value={startDate || null}
          label={t("newOrder.forms.dateForm.deliveryDateLabel")}
          placeholder={t("newOrder.forms.dateForm.deliveryDatePlaceholder")}
          helper={
            isWeekend(startDate) && (
              <span className="text-color-warning">({t("newOrder.forms.dateForm.deliveryDateWeekendHelper")})</span>
            )
          }
          onChange={onChangeDate}
          required
        />
      }
      startTimeInputSection={
        <Select
          id="inputTimeStart"
          name="startDate"
          label={t("common.forms.pickUpTimeLabel")}
          placeholder={t("common.forms.pickUpTimePlaceholder")}
          value={startDate ? startDate.toISOString() : ""}
          options={timePickUpOptions}
          onChange={onChangePickUpAt}
          helper={
            startDate && (
              <span className="d-flex flex-wrap">
                <span className="pr-2">{formatFullDate(startDate)}</span>

                {!isWeekend(startDate) && isPeakTime(startDate) && (
                  <span className="text-color-warning">({t("common.forms.pickUpTimePeakHelper")})</span>
                )}
              </span>
            )
          }
          disabled={!startDate}
          required
        />
      }
      endTimeInputSection={
        <Select
          id="inputTimeEnd"
          name="endDate"
          label={t("common.forms.dropOffTimeLabel")}
          placeholder={t("common.forms.dropOffTimePlaceholder")}
          value={endDate ? endDate.toISOString() : ""}
          options={timeToDropOffOptions}
          onChange={onChangeDropOffAt}
          helper={
            endDate && (
              <span className="d-flex flex-wrap">
                <span className="pr-2">{formatFullDate(endDate)}</span>
                {isExpress && (
                  <span className="text-color-warning">({t("common.forms.dropOffTimeExpressDeliveryHelper")})</span>
                )}
                {!isSameDay(startDate, endDate) && (
                  <span className="text-color-warning">({t("common.forms.dropOffTimeNextDayDeliveryHelper")})</span>
                )}
              </span>
            )
          }
          disabled={!startDate}
          required
        />
      }
      repeatFormSection={<RepeatSettingsForm />}
    />
  );
};

export default DateForm;
