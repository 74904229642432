import { Menu as MuiMenu } from "@mui/material";
import { first } from "lodash";
import { PropTypes } from "prop-types";

const Menu = ({ id, anchorEl, position, onClose, children, ...restProps }) => (
  <MuiMenu
    id={id}
    anchorEl={anchorEl}
    anchorOrigin={first(position)}
    transformOrigin={position[1]}
    onClose={onClose}
    open
    {...restProps}
  >
    {children}
  </MuiMenu>
);

Menu.defaultProps = {
  id: null,
  position: [],
};

Menu.propTypes = {
  id: PropTypes.string,
  anchorEl: PropTypes.instanceOf(Element).isRequired,
  onClose: PropTypes.func.isRequired,
  position: PropTypes.arrayOf(PropTypes.shape({ vertical: PropTypes.string, horizontal: PropTypes.string })),
  children: PropTypes.node.isRequired,
};

export default Menu;
