import { Divider } from "@mui/material";
import { PropTypes } from "prop-types";

const PackageFormLayout = ({
  titleSection,
  removeControlSection,
  packageIdInputSection,
  packageTypePickersSection,
  addControlSection,
  warningSection,
  showDivider,
  ...restProps
}) => (
  <div className="d-flex flex-wrap mb-5" {...restProps}>
    {showDivider && <Divider className="mb-4 w-100" />}

    <div className="d-flex justify-content-between align-items-end col-12 mb-4 px-0">
      <span className="text-fs-10 mt-4 pt-1">{titleSection}</span>

      {removeControlSection}
    </div>

    {packageIdInputSection}

    <div className="d-flex col-12 col-md-6 col-lg-5 px-0 pl-md-2 mt-4 mt-md-0">{packageTypePickersSection}</div>

    {addControlSection && (
      <div className="d-flex flex-column align-items-start mt-4 col-12 px-0">
        {addControlSection}

        {warningSection && <p className="mt-2 mb-5">{warningSection}</p>}

        <Divider className="mt-3 mb-1 w-100" />
      </div>
    )}
  </div>
);

PackageFormLayout.defaultProps = {
  removeControlSection: null,
  addControlSection: null,
  warningSection: null,
};

PackageFormLayout.propTypes = {
  titleSection: PropTypes.node.isRequired,
  removeControlSection: PropTypes.node,
  addControlSection: PropTypes.node,
  packageIdInputSection: PropTypes.node.isRequired,
  packageTypePickersSection: PropTypes.node.isRequired,
  warningSection: PropTypes.node,
  showDivider: PropTypes.bool.isRequired,
};

export default PackageFormLayout;
