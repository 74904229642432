import useOpenClose from "hooks/useOpenClose";
import AuthPageLayout from "library/Layouts/PageLayout/AuthPageLayout";
import SEO from "library/Molecules/SEO";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import AuthFormFooter from "./components/AuthFormFooter/AuthFormFooter";
import AuthFormHeader from "./components/AuthFormHeader/AuthFormHeader";
import ForgotPasswordForm from "./components/ForgotPasswordForm/ForgotPasswordForm";
import LoginForm from "./components/LoginForm/LoginForm";

const Login = () => {
  const { t } = useTranslation();

  const [isForgotPasswordFormOpen, openForgotPasswordForm, closeForgotPasswordForm] = useOpenClose(false);

  return (
    <AuthPageLayout
      titleSection={t("login.pageTitle", { context: isForgotPasswordFormOpen ? "forgotPassword" : "login" })}
      headerSection={<AuthFormHeader />}
      formDescriptionSection={
        <Trans
          i18nKey="login.pageDescription"
          context={isForgotPasswordFormOpen ? "forgotPassword" : "login"}
          components={{
            lnk: <Link to="/register" className="pl-1 text-color-primary text-fw-semibold" data-cy="register-link" />,
          }}
        />
      }
      footerSection={<AuthFormFooter />}
      data-cy={isForgotPasswordFormOpen ? "forgot-password-form" : "login-form"}
    >
      <SEO title={t("seo.helmetTitle", { name: t("seo.pages.login") })} />

      {isForgotPasswordFormOpen ? (
        <ForgotPasswordForm onOpenLogin={closeForgotPasswordForm} />
      ) : (
        <LoginForm onForgotPassword={openForgotPasswordForm} />
      )}
    </AuthPageLayout>
  );
};

export default Login;
