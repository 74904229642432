import { cx } from "@emotion/css";
import { useField } from "formik";
import useInputErrorHandler from "hooks/useInputErrorHandler";
import InputLayout from "library/Layouts/InputLayout/DefaultInputLayout";
import { values } from "lodash";
import { PropTypes } from "prop-types";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { useSelector } from "react-redux";
import { retrieveContactCity } from "store/features/contact.store";
import { COUNTRY_SHORTNAMES_BY_CITY, DEFAULT_COUNTRY } from "../../../../constants";
import styles from "./styles";

const PhoneInput = ({ id, name, label, className, onChange, helper, required, disabled, ...restProps }) => {
  const { t } = useTranslation();
  const city = useSelector(retrieveContactCity);

  const [{ ...field } = {}, { error } = {}] = useField({ name });
  const inputRef = useInputErrorHandler(error);

  const handleChange = useCallback(
    (phone, { dialCode }, _, formattedValue) => {
      if (!formattedValue.replace("+", "").replace(dialCode, "").length) formattedValue = "";
      onChange(name, formattedValue);
    },
    [onChange, name]
  );

  return (
    <InputLayout
      key={field.value}
      name={name}
      className={cx(className, "mb-3 pb-1")}
      label={label}
      error={t(error)}
      helper={helper}
      required={required}
    >
      <ReactPhoneInput
        {...field}
        containerClass={cx(styles.container, error && "Mui-error", "w-100")}
        inputClass={cx(styles.input, error && styles.error, disabled && styles.disabled, "w-100")}
        buttonClass={styles.arrow}
        inputProps={{ id, ref: inputRef, name }}
        onChange={handleChange}
        preferredCountries={values(COUNTRY_SHORTNAMES_BY_CITY)}
        disableDropdown={disabled}
        disabled={disabled}
        specialLabel=""
        {...(!field.value && { country: COUNTRY_SHORTNAMES_BY_CITY[city] || DEFAULT_COUNTRY })}
        {...restProps}
      />
    </InputLayout>
  );
};

PhoneInput.defaultProps = {
  id: "",
  label: "",
  className: "",
  helper: "",
  required: false,
  disabled: false,
};

PhoneInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  helper: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default PhoneInput;
