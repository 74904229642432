import PrimaryButton from "library/Atoms/Button/PrimaryButton";
import PageHeaderLayout from "library/Layouts/PageHeaderLayout";
import SEO from "library/Molecules/SEO";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchInvoicesRequest } from "store/requests/invoice.requests";
import InvoiceDetails from "./components/InvoiceDetails/InvoiceDetails";
import InvoiceDownloadButton from "./components/InvoiceDetails/InvoiceDownloadButton/InvoiceDownloadButton";
import InvoiceList from "./components/InvoiceList/InvoiceList";
import useInvoices from "./hooks/useInvoices";
import InvocesPageLayout from "./layouts/InvocesPageLayout/InvocesPageLayout";
import InvoiceDetailsDrawer from "./modals/InvoiceDetailsDrawer/InvoiceDetailsDrawer";

const Invoices = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [, { onDeselectInvoice }, { isIdle: invoiceListIdleStatus, isInvoiceListEmpty, isFiltersApplied }] =
    useInvoices();

  const isDashboardEmpty = useMemo(
    () => !isFiltersApplied && isInvoiceListEmpty,
    [isFiltersApplied, isInvoiceListEmpty]
  );

  const onMoveToNewOrder = useCallback(() => navigate("/new-order"), [navigate]);

  useEffect(() => {
    if (!invoiceListIdleStatus) return;
    dispatch(fetchInvoicesRequest());
  }, [invoiceListIdleStatus, dispatch]);

  useEffect(() => {
    return () => onDeselectInvoice();
  }, [onDeselectInvoice]);

  return (
    <InvocesPageLayout
      headerSection={
        <PageHeaderLayout
          contentClassName="flex-column"
          title={t("invoices.pageTitle")}
          subtitle={t("invoices.pageDescription", isDashboardEmpty && { context: "empty" })}
          headerControls={
            isDashboardEmpty && (
              <PrimaryButton className="w-100" label={t("common.buttons.newOrderBtn")} onClick={onMoveToNewOrder} />
            )
          }
          data-cy="invoice-header"
        />
      }
      listSection={!isDashboardEmpty && <InvoiceList />}
      modals={
        <InvoiceDetailsDrawer controlsSection={<InvoiceDownloadButton />}>
          <InvoiceDetails />
        </InvoiceDetailsDrawer>
      }
    >
      <SEO title={t("seo.helmetTitle", { name: t("seo.pages.invoices") })} />
    </InvocesPageLayout>
  );
};

export default Invoices;
