import { unwrapResult } from "@reduxjs/toolkit";
import ChooseAddressForm from "features/ChooseAddressForm";
import { FormikProvider, useFormik } from "formik";
import useDebounce from "hooks/useDebounce";
import useOpenClose from "hooks/useOpenClose";
import PrimaryButton from "library/Atoms/Button/PrimaryButton";
import TextButton from "library/Atoms/Button/TextButton";
import TextInput from "library/Atoms/Input/TextInput";
import ModalLayout from "library/Layouts/ModalLayout";
import { isEmpty, isEqual, omit, pick, set } from "lodash";
import LibraryConfirmResetModal from "pages/Library/modals/LibraryConfirmResetModal/LibraryConfirmResetModal";
import PropTypes from "prop-types";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  resetAddressStatus,
  retrieveSavedAddresses,
  retrieveSelectedAddress,
  setEditAddressStatus,
} from "store/features/library.store";
import { createNewAddressRequest, editAddressRequest } from "store/requests/library.requests";
import { validateSchema } from "utility/helpers/general";
import { DEFAULT_LOCATION_DATA } from "../../../../constants";
import SaveAddressFormSchema from "./AddressSettingsForm.validation";

const AddressSettingsForm = ({ onClose, isEdit }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { id, ...addressToEdit } = useSelector(retrieveSelectedAddress);
  const addressesList = useSelector(retrieveSavedAddresses);

  const saveAddressForm = useFormik({
    initialValues: {
      ...addressToEdit,
      location: isEmpty(addressToEdit) ? DEFAULT_LOCATION_DATA : addressToEdit.location,
    },
    onSubmit: (values, { setSubmitting }) => {
      dispatch(isEdit ? editAddressRequest({ id, ...values }) : createNewAddressRequest(values))
        .then(unwrapResult)
        .then(() => {
          setSubmitting(false);
          onClose();
        })
        .catch(() => setSubmitting(false));
    },
    validate: async (values) => {
      return validateSchema(SaveAddressFormSchema, { ...values, ...values.location });
    },
    validateOnChange: true,
  });
  const {
    values,
    setErrors,
    setFieldValue,
    submitForm,
    resetForm,
    dirty: isFormDirty,
    isValid,
    isSubmitting,
    errors,
    setValues,
  } = saveAddressForm;
  const { label: selectedLabel, ...selectedLocation } = values;

  const [isResetModalOpen, openResetModal, closeResetModal] = useOpenClose(false);

  const isLocationAlreadyExist = useMemo(() => {
    const sameAddress =
      addressesList.find(({ location }) => {
        const { location: currLocation } = selectedLocation;
        return isEqual(currLocation.geolocation, location.geolocation) && location.postcode === currLocation.postcode;
      }) || {};

    return !isEmpty(sameAddress) && sameAddress?.id !== id;
  }, [addressesList, selectedLocation, id]);

  const isLabelAlreadyExist = useMemo(() => {
    const sameAddress = addressesList.find(({ label }) => label === selectedLabel) || {};

    return !isEmpty(sameAddress) && sameAddress?.id !== id;
  }, [addressesList, selectedLabel, id]);

  const onChangeLocation = useCallback(
    ({ recipient, notes, ...location }) => setValues({ ...values, location, recipient, notes }),
    [values, setValues]
  );
  const onChangeAddressDetails = useCallback((name, value) => setFieldValue(name, value), [setFieldValue]);
  const debouncedChangeAddressDetails = useDebounce(onChangeAddressDetails);

  useEffect(() => {
    if (!isFormDirty) return;
    const errorKey = t("library.forms.addressSettingsForm.notUniqueError");

    let newErrors = { ...errors };

    if (isLocationAlreadyExist) newErrors = set(newErrors, "location", errorKey);
    else newErrors = omit(newErrors, ["location"]);

    if (isLabelAlreadyExist) newErrors = { ...newErrors, label: errorKey };
    else newErrors = errors.label === errorKey ? omit(newErrors, ["label"]) : newErrors;

    setErrors(newErrors);
  }, [isFormDirty, isLocationAlreadyExist, isLabelAlreadyExist, setErrors, errors, t]);

  useEffect(() => {
    isFormDirty ? dispatch(setEditAddressStatus()) : dispatch(resetAddressStatus());
  }, [dispatch, isFormDirty]);

  useEffect(() => {
    return () => dispatch(resetAddressStatus());
  }, [dispatch]);

  return (
    <FormikProvider value={saveAddressForm}>
      <ModalLayout
        contentSection={
          <>
            <TextInput
              id="label"
              name="label"
              className="col-12 col-md-7 col-lg-7 px-0 pr-md-2"
              label={t("common.forms.nameLabel")}
              placeholder={t("common.forms.namePlaceholder")}
              onChange={debouncedChangeAddressDetails}
              required
            />

            <ChooseAddressForm
              name="save-address"
              className="mb-1"
              address={{ ...pick(selectedLocation, ["recipient", "notes"]), ...selectedLocation.location }}
              onAddressChanged={onChangeLocation}
              withSavedAddresses={false}
              required
            />
          </>
        }
        modalControls={
          <>
            <PrimaryButton
              id="submit-saved-address-form-btn"
              className="w-100"
              label={isEdit ? t("common.buttons.saveChangesBtn") : t("library.addAddressBtn")}
              onClick={submitForm}
              loading={isSubmitting}
              disabled={!isFormDirty || !isValid || !isEmpty(errors)}
            />

            <TextButton
              id="reset-saved-address-form-btn"
              className="mt-4 py-0"
              label={t("common.buttons.resetBtn")}
              onClick={openResetModal}
              disabled={!isFormDirty}
            />
          </>
        }
        modals={
          isResetModalOpen && <LibraryConfirmResetModal onConfirm={resetForm} onClose={closeResetModal} isAddress />
        }
      />
    </FormikProvider>
  );
};

AddressSettingsForm.defaultProps = {
  isEdit: false,
};

AddressSettingsForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
};

export default AddressSettingsForm;
