import { createAsyncThunk } from "@reduxjs/toolkit";
import { addDays } from "date-fns";
import PartnerApi from "utility/api";
import { formatDate } from "utility/date-fns";
import { calculatePackagesAmountByType } from "utility/helpers/orders";
import { getUserUID } from "utility/helpers/user";
import { DEFAULT_CITY, REPEAT_ORDER_TYPES } from "../../constants";
import { refetchOrders } from "../features/order.store";

export const createNewOrderRequest = createAsyncThunk(
  "newOrder/createNewOrderRequest",
  async ({ order, customer }, { dispatch, rejectWithValue }) => {
    const { repeat_settings } = order;

    const data = {
      ...order,
      repeat_settings:
        repeat_settings.type === REPEAT_ORDER_TYPES.EXACT_DATES
          ? { ...repeat_settings, dates: repeat_settings.dates.map((date) => formatDate(date)) }
          : repeat_settings,
      contact_email: customer.email,
      contact_phone: customer.phone,
      contact_name: `${customer.firstName} ${customer.lastName}`,
    };

    try {
      const result = await PartnerApi.createOrder(data);
      dispatch(refetchOrders());
      return result;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const calculateOrderPriceRequest = createAsyncThunk(
  "newOrder/calculateOrderPriceRequest",
  async (
    { startDate = new Date(addDays(new Date(), 1)), pick_up, drops, isExpress = false, ignoreCommonFees = false },
    { getState, rejectWithValue }
  ) => {
    const { contact } = getState();

    const order = {
      startDate,
      origin: pick_up.geolocation,
      waypoints: drops.map(({ geolocation, postcode, packages }) => {
        return {
          position: geolocation,
          postcode,
          packages: calculatePackagesAmountByType(packages),
        };
      }),
      isExpress,
      ignoreCommonFees,
    };

    try {
      const result = await PartnerApi.calculateOrderPrice({ order, userId: getUserUID() });

      const { price, starting_price, miles, meter, currency } = result.data;
      return {
        ...result,
        data: {
          price,
          starting_price,
          distance: contact.profile.city === DEFAULT_CITY ? miles : meter / 1000,
          currency,
        },
      };
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const parseCsvQuoteRequest = createAsyncThunk(
  "newOrder/parseCsvQuoteRequest",
  async (csv, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append("file", csv);

      return await PartnerApi.paseCsvQuote(formData);
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);
