import { PropTypes } from "prop-types";

const ProfileFormLayout = ({ contactFormSection, invoiceFormSection, controlsSection, modals }) => (
  <>
    {contactFormSection}

    {invoiceFormSection}

    {controlsSection}

    {modals}
  </>
);

ProfileFormLayout.propTypes = {
  contactFormSection: PropTypes.node.isRequired,
  invoiceFormSection: PropTypes.node.isRequired,
  controlsSection: PropTypes.node.isRequired,
  modals: PropTypes.node.isRequired,
};

export default ProfileFormLayout;
