import useDeviceType from "hooks/useDeviceType";
import StatusIndicator from "library/Molecules/StatusViews/StatusIndicator";
import StatusStepper from "library/Molecules/StatusViews/StatusStepper";
import { getOrderStatusColor, getOrderStatusIcon } from "pages/Dashboard/utils/helper";
import { PropTypes } from "prop-types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import OrderStatusType from "types/models/OrderStatus";
import { prettifyStatusLabel } from "utility/helpers/general";
import { checkActiveOrderStatus } from "utility/helpers/orders";
import { ACCEPTED_STATUS, CLAIMED_STATUS, DELIVERED_STATUS, ORDER_STATUSES_LIST } from "../../../../../constants";

const StatusBanner = ({ id, status, isDrop }) => {
  const { t } = useTranslation();
  const { isMobile } = useDeviceType();

  const steps = useMemo(() => {
    return ORDER_STATUSES_LIST.reduce((prev, s) => {
      if (![ACCEPTED_STATUS, DELIVERED_STATUS, CLAIMED_STATUS].includes(s)) return [...prev];

      const label = t([`utils.deliveryStatuses.${s}`, prettifyStatusLabel(s)]);
      return [...prev, { label, status: s }];
    }, []);
  }, [t]);

  const activeStep = useMemo(() => steps.findIndex((step) => step.status === status), [steps, status]);

  const showStepper = useMemo(() => {
    if (!ORDER_STATUSES_LIST.includes(status) || isMobile) return false;
    return checkActiveOrderStatus(status);
  }, [isMobile, status]);

  return (
    <div
      id={`${isDrop ? "drop" : "order"}-status-stepper-${id}`}
      className="w-100"
      data-cy={
        // eslint-disable-next-line no-nested-ternary
        showStepper
          ? `status-stepper-${status}`
          : isDrop && !isMobile
          ? `status-chip-${status}`
          : `status-labeled-icon-${status}`
      }
    >
      {showStepper ? (
        <StatusStepper
          activeStep={activeStep}
          steps={steps}
          renderColor={getOrderStatusColor}
          renderIcon={getOrderStatusIcon}
        />
      ) : (
        <StatusIndicator
          status={status}
          label={t([`utils.deliveryStatuses.${status}`, prettifyStatusLabel(status)])}
          renderColor={getOrderStatusColor}
          renderIcon={getOrderStatusIcon}
          variant={isDrop && !isMobile ? "chip" : "labeled-icon"}
        />
      )}
    </div>
  );
};

StatusBanner.defaultProps = {
  status: "pending",
  isDrop: false,
};

StatusBanner.propTypes = {
  id: PropTypes.string.isRequired,
  status: OrderStatusType,
  isDrop: PropTypes.bool,
};

export default StatusBanner;
