// eslint-disable-next-line import/prefer-default-export
export const prettifyAddressComponents = (address_components) => {
  const city = address_components?.find(({ types }) => types.includes("locality"))?.long_name || "";
  const country = address_components?.find(({ types }) => types.includes("country"))?.long_name || "";

  const secondLineAddress = address_components?.find(({ types }) => types.includes("route"))?.long_name || "";
  const buildingOrFlatNumber =
    address_components?.find(({ types }) => types.includes("street_number"))?.long_name || "";

  const postcode = address_components?.find(({ types }) => types.includes("postal_code"))?.long_name || "";

  return { city, country, secondLineAddress, buildingOrFlatNumber, postcode };
};
