import { addMinutes, isAfter, setHours, setMinutes } from "date-fns";
import { first, toPairs } from "lodash";
import { v4 as uuidv4 } from "uuid";
import {
  ACCEPTED_STATUS,
  CLAIMED_STATUS,
  DEFAULT_LOCATION_DATA,
  DELIVERED_STATUS,
  LARGE_PACKAGE_TYPE,
  MEDIUM_PACKAGE_TYPE,
  PENDING_STATUS,
  SMALL_PACKAGE_TYPE,
  WORKING_HOURS_CONFIG,
} from "../../constants";
import { formatHours } from "../date-fns";
import i18n from "../i18next";

export const generateNewPackage = () => ({
  reference: uuidv4(),
  parcelCustomerId: "",
  type: SMALL_PACKAGE_TYPE,
  quantity: 1,
});

export const generateNewDrop = () => ({
  reference: uuidv4(),
  ...DEFAULT_LOCATION_DATA,
  packages: [generateNewPackage()],
});

export const prettifyDropReference = (fullRef) => first(fullRef.split("-")).toUpperCase();

export const prettifyParcelCustomerIds = (packages) => {
  return packages.map(({ parcelCustomerId }) => parcelCustomerId).join(", ");
};

export const prettifyRiderName = (name) => name || "N/A";

export const prettifyLocation = ({ location, postcode }) => `${location || ""} | ${postcode || ""}`;

export const calculatePackagesAmount = (drops = []) => {
  return drops.reduce((prevTotal, { packages }) => {
    return prevTotal + packages.reduce((prevAmount, { quantity = 1 }) => prevAmount + quantity, 0);
  }, 0);
};

export const calculatePackagesAmountByType = (packages = []) => {
  return packages.reduce(
    (prev, { type, quantity }) => {
      if (type === SMALL_PACKAGE_TYPE) return { ...prev, [SMALL_PACKAGE_TYPE]: prev[SMALL_PACKAGE_TYPE] + quantity };
      if (type === MEDIUM_PACKAGE_TYPE) return { ...prev, [MEDIUM_PACKAGE_TYPE]: prev[MEDIUM_PACKAGE_TYPE] + quantity };
      if (type === LARGE_PACKAGE_TYPE) return { ...prev, [LARGE_PACKAGE_TYPE]: prev[LARGE_PACKAGE_TYPE] + quantity };
      return { ...prev, [type]: quantity };
    },
    { [SMALL_PACKAGE_TYPE]: 0, [MEDIUM_PACKAGE_TYPE]: 0, [LARGE_PACKAGE_TYPE]: 0 }
  );
};

export const prettifyPackagesAmount = (packages) => {
  return toPairs(calculatePackagesAmountByType(packages))
    .reduce((prev, [type, quantity]) => {
      return quantity === 0
        ? prev
        : [...prev, `${quantity} ${i18n.t([`utils.packageSizes.${type}`, type]).toLocaleLowerCase()}`];
    }, [])
    .join(", ");
};

export const checkActiveOrderStatus = (status) => {
  return ACCEPTED_STATUS === status || DELIVERED_STATUS === status || CLAIMED_STATUS === status;
};

export const checkCancellationAvailability = (status) => [PENDING_STATUS, ACCEPTED_STATUS].includes(status);

export const generateTimePickerData = (date, config = {}) => {
  const { startTime, endTime, withoutPastLimit = false } = config;
  const TIME_RESTRICTIONS = {
    startTime: startTime || WORKING_HOURS_CONFIG.start,
    endTime: endTime || WORKING_HOURS_CONFIG.end,
  };

  const hours = Array(TIME_RESTRICTIONS.endTime - TIME_RESTRICTIONS.startTime)
    .fill()
    .map((_, index) => TIME_RESTRICTIONS.startTime + index);

  const positions = [0, 15, 30, 45];

  const lastValue = setHours(setMinutes(new Date(date), 0), TIME_RESTRICTIONS.endTime);

  const options = hours.reduce((prevValues, hour) => {
    const dateWithHours = setHours(new Date(date), hour);
    const dateWithHoursAndMinutes = positions.map((position) => {
      const value = setMinutes(dateWithHours, position);
      return { value: new Date(value).toISOString(), label: formatHours(value) };
    });

    return [...prevValues, ...dateWithHoursAndMinutes];
  }, []);

  const data = [...options, { value: new Date(lastValue).toISOString(), label: formatHours(lastValue) }];

  if (withoutPastLimit) return data;
  return data.filter(({ value }) => isAfter(new Date(value), addMinutes(new Date(), 15)));
};

export const getPriceDiscount = ({ starting_price, price }) => {
  const discount = (Number(starting_price) - Number(price)).toFixed(2);
  const percentage = discount ? ((discount * 100) / Number(price)).toFixed(2) : 0;

  return { discount, percentage };
};
