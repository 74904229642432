import AddPackageIcon from "@mui/icons-material/AddCircleOutline";
import RemovePackageIcon from "@mui/icons-material/HighlightOff";
import { useFormikContext } from "formik";
import useDebounce from "hooks/useDebounce";
import TextButton from "library/Atoms/Button/TextButton";
import TextInput from "library/Atoms/Input/TextInput";
import Select from "library/Atoms/Select";
import Tooltip from "library/Atoms/Tooltip";
import { keys, size } from "lodash";
import PackageFormLayout from "pages/NewOrder/layouts/FormLayout/PackageFormLayout/PackageFormLayout";
import PropTypes from "prop-types";
import { useCallback, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { generateNewPackage } from "utility/helpers/orders";
import commonStyles from "utility/ui/common";
import { MAX_PACKAGE_AMOUNT, PACKAGE_TYPES, QUANTITY_OPTIONS } from "../../../../../../constants";

const PackageForm = ({ reference, pacReference }) => {
  const { t } = useTranslation();

  const {
    values: { drops },
    setFieldValue,
  } = useFormikContext();

  const dropIndex = useMemo(() => {
    return drops.findIndex(({ reference: dropReference }) => reference === dropReference);
  }, [drops, reference]);
  const drop = useMemo(() => drops[dropIndex], [dropIndex, drops]);

  const pacIndex = useMemo(() => {
    return drop?.packages.findIndex(({ reference: packageReference }) => pacReference === packageReference);
  }, [drops, dropIndex, pacReference]);
  const isLastPackage = useMemo(() => pacIndex === size(drop?.packages) - 1, [pacIndex, drops]);

  const debouncedChangingPackageDetails = useDebounce(setFieldValue);

  const onAddNewPackage = useCallback(() => {
    setFieldValue(`drops[${dropIndex}].packages`, [...drop.packages, generateNewPackage()]);
  }, [setFieldValue, drops, dropIndex]);
  const onRemovePackage = useCallback(() => {
    setFieldValue(
      `drops[${dropIndex}].packages`,
      drop?.packages.filter((_, index) => index !== pacIndex)
    );
  }, [drops, dropIndex, pacIndex, setFieldValue]);

  return (
    <PackageFormLayout
      titleSection={t("newOrder.forms.dropOffForm.dropOffFormProductSubtitle")}
      removeControlSection={
        size(drop?.packages) > 1 && (
          <TextButton
            id={`remove-${dropIndex}-package-${pacIndex}-button`}
            label={t("newOrder.forms.dropOffForm.packageForm.removePackageBtn")}
            onClick={onRemovePackage}
            startIcon={<RemovePackageIcon fontSize="small" />}
          />
        )
      }
      packageIdInputSection={
        <TextInput
          id={`drop-${dropIndex}-parcelCustomerId-${pacIndex}`}
          name={`drops[${dropIndex}].packages[${pacIndex}].parcelCustomerId`}
          className="col-12 col-md-6 col-lg-7 px-0 pr-md-2"
          label={t("newOrder.forms.dropOffForm.packageForm.productNameLabel")}
          placeholder={t("newOrder.forms.dropOffForm.packageForm.productNamePlaceholder")}
          onChange={debouncedChangingPackageDetails}
        />
      }
      packageTypePickersSection={
        <>
          <Select
            id={`drop-${dropIndex}-parcelTypeSelect-${pacIndex}`}
            name={`drops[${dropIndex}].packages[${pacIndex}].type`}
            className="col-6 pl-0 pr-2"
            label={
              <>
                <span>{t("newOrder.forms.dropOffForm.packageForm.sizeLabel")}</span>
                <Tooltip className="d-flex flex-column">
                  {keys(PACKAGE_TYPES).map((p_type) => (
                    <span key={PACKAGE_TYPES[p_type]}>
                      {t(`utils.packageSizes.${p_type}`)}: {PACKAGE_TYPES[p_type]}
                    </span>
                  ))}
                </Tooltip>
              </>
            }
            options={keys(PACKAGE_TYPES).map((value) => ({ value, label: t(`utils.packageSizes.${value}`) }))}
            onChange={setFieldValue}
            required
          />

          <Select
            id={`drop-${dropIndex}-parcelQuantitySelect-${pacIndex}`}
            name={`drops[${dropIndex}].packages[${pacIndex}].quantity`}
            className="col-6 pl-2 pr-0"
            label={t("newOrder.forms.dropOffForm.packageForm.quantityLabel")}
            options={QUANTITY_OPTIONS.map((value) => ({ value, label: value }))}
            onChange={setFieldValue}
            required
          />
        </>
      }
      addControlSection={
        isLastPackage && (
          <TextButton
            id={`add-${dropIndex}-package-button`}
            className={commonStyles.textBtnMargin}
            label={t("newOrder.forms.dropOffForm.packageForm.addPackageBtn")}
            onClick={onAddNewPackage}
            startIcon={<AddPackageIcon fontSize="small" />}
            disabled={size(drop.packages) >= MAX_PACKAGE_AMOUNT}
          />
        )
      }
      warningSection={
        size(drops) >= MAX_PACKAGE_AMOUNT && (
          <Trans
            i18nKey="newOrder.forms.dropOffForm.packageForm.maximumPackageMessage"
            tOptions={{ email: "delivery@pedivan.co.uk", maxPackage: MAX_PACKAGE_AMOUNT }}
            components={{ lnk: <a href="mailto:delivery@pedivan.co.uk" className="text-color-primary" /> }}
          />
        )
      }
      showDivider={pacIndex !== 0}
      data-cy={`drops[${dropIndex}]-package[${pacIndex}]-form`}
    />
  );
};

PackageForm.propTypes = {
  reference: PropTypes.string.isRequired,
  pacReference: PropTypes.string.isRequired,
};

export default PackageForm;
