import { cx } from "@emotion/css";
import { keys } from "lodash";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { COUNTRY_SHORTNAMES_BY_LOCALE, DEFAULT_COUNTRY, DEFAULT_LOCALE, LOCALES_ENUM } from "../../../constants";
import styles from "./styles";

const Flag = ({ locale, className }) => {
  const country = useMemo(() => COUNTRY_SHORTNAMES_BY_LOCALE[locale] || DEFAULT_COUNTRY, [locale]);

  return (
    <img
      className={cx(className, styles.flag, "h-100 w-100")}
      src={`https://flagcdn.com/h20/${country}.png`}
      srcSet={`https://flagcdn.com/h40/${country}.png 2x, https://flagcdn.com/h60/${country}.png 3x"`}
      alt={locale}
    />
  );
};

Flag.defaultProps = {
  locale: DEFAULT_LOCALE,
  className: "",
};

Flag.propTypes = {
  locale: PropTypes.oneOf(keys(LOCALES_ENUM)),
  className: PropTypes.string,
};

export default Flag;
