import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { retrieveSavedOrdersStatus } from "store/features/library.store";
import { fetchSavedOrdersRequest } from "store/requests/library.requests";
import useNewOrderForm from "./hooks/useNewOrderForm";

const withImportedOrder = (Component) => (props) => {
  const dispatch = useDispatch();

  const { idle: ordersIdleStatus } = useSelector(retrieveSavedOrdersStatus);

  const [, , { setOrderDetails }] = useNewOrderForm();

  const handleImportOrder = useCallback(
    ({ order }) => {
      setOrderDetails({});
      setOrderDetails({ ...order, startDate: new Date(order.startDate), endDate: new Date(order.endDate) });
    },
    [setOrderDetails]
  );

  useEffect(() => {
    if (!ordersIdleStatus) return;
    dispatch(fetchSavedOrdersRequest());
  }, [ordersIdleStatus, dispatch]);

  return <Component {...props} handleImportOrder={handleImportOrder} />;
};

export default withImportedOrder;
