import { format as dateFnsFormat, formatDistance as dateFnsFormatDistance } from "date-fns";
import { de, enGB } from "date-fns/locale";
import { isDate } from "lodash";
import { DEFAULT_LOCALE } from "../constants";

const dateFnsLocales = { enGB, de };

export const format = (date, formatStr) => {
  let d = date;
  if (!isDate(d)) d = new Date(d);

  return dateFnsFormat(d, formatStr, {
    locale: dateFnsLocales[localStorage.getItem("i18nextLng") || DEFAULT_LOCALE.replace("-", "")],
  });
};

export const formatHours = (date) => format(date, "HH:mm");
export const formatDateFlat = (date) => format(date, "MMM dd yyyy");
export const formatDate = (date) => format(date, "MMM dd, yyyy");
export const formatDateNumeric = (date) => format(date, "MM/dd/yy");
export const formatFullDate = (date) => format(date, "MMM dd, yyyy HH:mm");

export const formatDistance = (dateFrom, dateTo) => {
  let [df, dt] = [dateFrom, dateTo];
  if (!isDate(df)) df = new Date(df);
  if (!isDate(dt)) dt = new Date(dt);

  return dateFnsFormatDistance(df, dt, {
    locale: dateFnsLocales[localStorage.getItem("i18nextLng") || DEFAULT_LOCALE.replace("-", "")],
  });
};
