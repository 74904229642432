import Pagination from "@mui/material/Pagination";
import ListFilters from "features/ListFillters";
import EmptyPageText from "library/Atoms/Text/EmptyPageText";
import useInvoices from "pages/Invoices/hooks/useInvoices";
import InvoiceListLayout from "pages/Invoices/layouts/InvoiceListLayout/InvoiceListLayout";
import { useTranslation } from "react-i18next";
import InvoiceListSkeleton from "../../skeletons/InvoiceListSkeleton/InvoiceListSkeleton";
import InvoiceItem from "./InvoiceItem/InvoiceItem";

const InvoicesList = () => {
  const { t } = useTranslation();

  const [
    { invoices, pagination },
    { onChangeFilters, onChangePage },
    { isListLoading, isFirstLoading, isInvoiceListEmpty, isFiltersApplied },
  ] = useInvoices();

  return (
    <InvoiceListLayout
      filtersSection={<ListFilters onFilterList={onChangeFilters} isLoading={isFirstLoading} />}
      listSection={!isListLoading && invoices.map((invoice) => <InvoiceItem key={invoice.id} invoice={invoice} />)}
      skeletonSection={isListLoading && <InvoiceListSkeleton />}
      emptyListSection={
        isInvoiceListEmpty &&
        isFiltersApplied && (
          <EmptyPageText data-cy="empty-invoices-list-text">{t("invoices.emptyInvoiceListMessage")}</EmptyPageText>
        )
      }
      paginationSection={
        pagination.total_pages > 1 && (
          <Pagination
            className="mx-auto pt-3"
            page={pagination.active_page}
            count={pagination.total_pages}
            onChange={onChangePage}
            disabled={isListLoading}
            color="primary"
            size="small"
            data-cy="list-pagination"
          />
        )
      }
    />
  );
};

export default InvoicesList;
