import { MenuItem } from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import useAuthState from "hooks/useAuthState";
import useOpenClose from "hooks/useOpenClose";
import Flag from "library/Atoms/Flag";
import Spinner from "library/Atoms/Spinner";
import Menu from "library/Molecules/Menu";
import { keys } from "lodash";
import { PropTypes } from "prop-types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { updateContactLocaleRequest } from "store/requests/contact.requests";
import { LOCALES_ENUM } from "../../constants";

const LanguageSwitcher = ({ anchorEl, onClose }) => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const { isAuthenticated } = useAuthState();

  const [newLocale, setNewLocale] = useState("");

  const [languageLoading, startLanguageLoading, cancelLanguageLoading] = useOpenClose(false);

  const handleSwitchLanguage = useCallback(
    (locale) => {
      if (i18n.language === locale) return;

      if (!isAuthenticated) {
        i18n.changeLanguage(locale);
        onClose();
        return;
      }

      setNewLocale(locale);
      startLanguageLoading();

      dispatch(updateContactLocaleRequest(locale))
        .then(unwrapResult)
        .then(() => {
          i18n.changeLanguage(locale);
          onClose();
          setNewLocale("");
          cancelLanguageLoading();
        })
        .catch(() => {
          setNewLocale("");
          cancelLanguageLoading();
        });
    },
    [i18n, cancelLanguageLoading, dispatch, onClose, startLanguageLoading, isAuthenticated]
  );

  return (
    <Menu
      id="language-switcher-menu"
      anchorEl={anchorEl}
      onClose={onClose}
      position={[
        { vertical: "bottom", horizontal: "right" },
        { vertical: "bottom", horizontal: "left" },
      ]}
    >
      {keys(LOCALES_ENUM).map((locale) => (
        <MenuItem
          key={locale}
          selected={i18n.language === locale}
          onClick={() => handleSwitchLanguage(locale)}
          aria-label={locale}
          aria-selected={i18n.language === locale}
        >
          {newLocale === locale && languageLoading ? <Spinner size={16} /> : <Flag locale={locale} />}
          <span className="text-fs-14 text-fw-semibold pl-3">{LOCALES_ENUM[locale]}</span>
        </MenuItem>
      ))}
    </Menu>
  );
};

LanguageSwitcher.propTypes = {
  anchorEl: PropTypes.instanceOf(Element).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default LanguageSwitcher;
