import { createTheme } from "@mui/material/styles";
import palette from "./palette";

const themeMui = createTheme({
  typography: {
    fontSize: 24,
    fontFamily: ["Inter", "sans-serif"].join(","),
  },
  breakpoints: { values: { xs: 0, sm: 576, md: 768, lg: 992, xl: 1200 } },
  palette,
  components: {
    MuiButtonBase: {
      styleOverrides: {
        root: {
          outline: "none !important",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: 20,
          maxWidth: 200,
          borderRadius: 12,
          fontSize: 14,
          textTransform: "none",
          outline: "none !important",
        },
        outlined: ({ theme }) => ({
          maxHeight: 40,
          minHeight: 40,
          fontWeight: 600,
          color: theme.palette.primary.main,
        }),
        contained: ({ theme }) => ({
          maxHeight: 40,
          minHeight: 40,
          fontWeight: 600,
          color: theme.palette.white,
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
            color: theme.palette.white,
          },
        }),
        textSizeSmall: ({ theme }) => ({
          fontSize: 12,
          fontWeight: 400,
          textDecoration: "underline",
          "&:hover": {
            textDecoration: "underline",
          },
          "&.Mui-disabled, &.Mui-disabled:hover": {
            textDecoration: "none",
            color: theme.palette.grey400,
          },
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontSize: 12,
          "&.Mui-disabled": {
            backgroundColor: theme.palette.grey50,
          },
        }),
        inputMultiline: {
          paddingTop: "0 !important",
          paddingBottom: "0 !important",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          minHeight: 42.25,
          borderColor: palette.grey400,
          "&:hover .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.primary.main,
            borderWidth: 1,
          },
        }),
        notchedOutline: ({ theme }) => ({
          borderColor: theme.palette.grey200,
          borderRadius: 8,
          top: 0,
          "& legend": {
            display: "none",
          },
        }),
        input: ({ theme }) => ({
          paddingTop: 12.5,
          paddingBottom: 12.5,
          cursor: "pointer",
          "&::placeholder": {
            color: theme.palette.grey400,
            opacity: 1,
          },
        }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          flex: 1,
        },
        indicator: ({ theme }) => ({
          width: 4,
          left: 0,
          backgroundColor: theme.palette.primary.main,
        }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          minHeight: 52,
          maxHeight: 52,
          minWidth: 60,
          maxWidth: 230,
          justifyContent: "flex-start",
          borderRadius: "0 12px 12px 0",
          fontSize: 14,
          fontWeight: 600,
          color: theme.palette.white,
          textTransform: "none",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          opacity: 1,
          "&.Mui-selected, &.Mui-selected:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.11)",
            color: theme.palette.primary.main,
          },
          "&:hover": {
            color: theme.palette.grey200,
          },
          "& .MuiTab-iconWrapper": {
            minWidth: 25,
            marginRight: 15,
          },
          "&.Mui-selected path[fill='#fff']": {
            fill: theme.palette.primary.main,
          },
          "&.Mui-disabled": {
            opacity: "0.38",
            color: theme.palette.white,
          },
        }),
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          marginLeft: -5,
        },
        separator: ({ theme }) => ({
          color: theme.palette.grey300,
          marginLeft: 0,
          marginRight: 0,
        }),
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          transition: "all 500ms ease-in-out",
          boxShadow: "1px 0px 4px #00000029",
          zIndex: 1,
        },
        paper: ({ theme }) => ({
          backgroundColor: "transparent",
          color: theme.palette.grey900,
          zIndex: 1,
          boxShadow: "none",
        }),
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          height: "50%",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 12,
          minHeight: "auto",
        },
      },
    },
    MuiPopper: {
      styleOverrides: {
        root: {
          height: "auto",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        input: {
          boxSizing: "inherit",
        },
        noOptions: {
          fontSize: 12,
        },
        option: {
          fontSize: 12,
        },
        loading: {
          fontSize: 12,
        },
        popupIndicator: ({ theme }) => ({
          color: theme.palette.primary.main,
        }),
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          fontSize: 12,
        },
        select: {
          "&:focus": {
            background: "none",
          },
        },
        icon: ({ theme }) => ({
          color: theme.palette.primary.main,
        }),
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          minHeight: "auto",
          flexDirection: "row-reverse",
          padding: 0,
        },
        content: {
          paddingLeft: 5,
        },
        expandIconWrapper: ({ theme }) => ({
          color: theme.palette.primary.main,
        }),
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: ({ theme }) => ({
          backgroundColor: theme.palette.grey100,
          borderWidth: 1,
          borderColor: theme.palette.grey200,
          borderStyle: "solid",
          color: theme.palette.secondary.main,
        }),
        arrow: ({ theme }) => ({
          "&::before": {
            backgroundColor: theme.palette.grey100,
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: theme.palette.grey200,
          },
        }),
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "row-reverse",
          alignItems: "center",
          gap: 5,
          width: "fit-content",
          height: 25,
        },
        filled: ({ theme }) => ({
          color: theme.palette.white,
        }),
        label: {
          paddingLeft: 10,
          paddingRight: 8,
          fontSize: 12,
          fontWeight: 600,
        },
        icon: {
          marginLeft: -6,
          marginRight: 5,
          fontSize: 16,
          color: "inherit",
        },
      },
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          "& .PrivatePickersSlideTransition-root": {
            minHeight: 240,
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        iconContainer: {
          position: "absolute",
          display: "flex",
          alignItems: "center",
          height: 20,
          zIndex: 1,
        },
        label: ({ theme }) => ({
          fontSize: 12,
          fontWeight: "inherit",
          color: theme.palette.secondary.main,
        }),
        labelContainer: {
          marginTop: 20,
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        root: ({ theme }) => ({
          top: 9.5,
          left: "calc(-50% + 4px)",
          right: "calc(50% + 4px)",
          "&.Mui-completed .MuiStepConnector-line, &.Mui-active .MuiStepConnector-line": {
            borderColor: theme.palette.primary.main,
          },
        }),
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          margin: "0 5px",

          fontSize: "1rem",
          textAlign: "right",
          lineHeight: "1.9rem",
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          padding: 0,
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontSize: 12,
          "&.Mui-selected": {
            color: theme.palette.white,
          },
        }),
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: ({ theme }) => ({
          margin: "-10px",
          padding: "10px",
          color: theme.palette.grey400,
        }),
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          margin: "0 -6px",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderColor: theme.palette.grey200,
        }),
      },
    },
  },
});

export const { transitions } = themeMui;

export default themeMui;
