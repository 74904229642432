import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import { formatHours } from "utility/date-fns";

const OrderBriefInfo = ({ startDate, endDate, packages }) => {
  const { t } = useTranslation();

  return (
    <span className="w-100 text-truncate">
      <span className="text-truncate text-center">
        {formatHours(startDate)} - {formatHours(endDate)}
      </span>

      <span className="px-1">|</span>

      <span className="text-truncate text-center">{t("common.entities.order.packagesCount", { count: packages })}</span>
    </span>
  );
};

OrderBriefInfo.defaultProps = {
  packages: 0,
};

OrderBriefInfo.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  packages: PropTypes.number,
};

export default OrderBriefInfo;
