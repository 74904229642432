import ConfirmationModal from "library/Molecules/Modal/ConfirmationModal";
import useNewOrderForm from "pages/NewOrder/hooks/useNewOrderForm";
import { PropTypes } from "prop-types";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { NEW_ORDER_FORM_STEPS } from "../../../../constants";

const ResetOrderModal = ({ onConfirm, onDecline, onClose }) => {
  const { t } = useTranslation();

  const [, { step }] = useNewOrderForm();

  const isResetStep = useMemo(() => step === NEW_ORDER_FORM_STEPS.SET_DELIVERY, [step]);

  const handleConfirm = useCallback(() => {
    onConfirm();
    onClose();
  }, [onClose, onConfirm]);

  const handleDecline = useCallback(() => {
    onDecline();
    onClose();
  }, [onClose, onDecline]);

  return (
    <ConfirmationModal
      id="reset-new-order-form-modal"
      title={t(isResetStep ? "common.modals.confirmModal.title" : "newOrder.modals.resetOrderModal.title")}
      description={t(
        isResetStep ? "common.modals.confirmModal.description" : "newOrder.modals.resetOrderModal.description"
      )}
      onConfirm={handleConfirm}
      onDecline={handleDecline}
      declineBtnText={t("common.buttons.backToBtn", { context: "order" })}
      confirmBtnText={
        isResetStep
          ? t("newOrder.modals.resetOrderModal.resetOrderBtn")
          : t("newOrder.modals.resetOrderModal.cancelOrderBtn")
      }
    />
  );
};

ResetOrderModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ResetOrderModal;
