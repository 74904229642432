import { Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import TextButton from "library/Atoms/Button/TextButton";
import { PropTypes } from "prop-types";

const Breadcrumbs = ({ data, step, ...restProps }) => (
  <MuiBreadcrumbs separator="›" {...restProps}>
    {data.map(({ label, onClick }, index) => (
      <TextButton
        key={label}
        className="px-2"
        label={label}
        onClick={onClick}
        disabled={step < index}
        size="small"
        aria-selected={step === index}
      />
    ))}
  </MuiBreadcrumbs>
);

Breadcrumbs.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, onClick: PropTypes.func })).isRequired,
  step: PropTypes.number.isRequired,
};

export default Breadcrumbs;
