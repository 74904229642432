import ChooseAddressForm from "features/ChooseAddressForm";
import { useFormikContext } from "formik";
import { keys, pick } from "lodash";
import TaskFormLayout from "pages/NewOrder/layouts/FormLayout/TaskFormLayout/TaskFormLayout";
import { generateNewOrderRecipient } from "pages/NewOrder/utils/helper";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { retrieveContact } from "store/features/contact.store";
import { DEFAULT_LOCATION_DATA } from "../../../../../constants";

const PickUpForm = () => {
  const { t } = useTranslation();

  const contact = useSelector(retrieveContact);

  const {
    values: { pick_up },
    setFieldValue,
  } = useFormikContext();

  const onChangePickUpAddress = useCallback(
    ({ notes, recipient, ...address }) => {
      let newPickUp = { ...address, notes: notes ?? "" };

      if (recipient.name || recipient.phone || recipient.email) newPickUp = { ...newPickUp, recipient };
      else newPickUp = { ...newPickUp, recipient: generateNewOrderRecipient(contact) };

      setFieldValue("pick_up", newPickUp);
    },
    [setFieldValue, pick_up, contact]
  );

  return (
    <TaskFormLayout
      titleSection={t("newOrder.forms.pickUpForm.formTitle")}
      addressFormSection={
        <ChooseAddressForm
          name="pick_up"
          className="col-12 px-0"
          label={t("newOrder.forms.pickUpForm.formTitle").toLocaleLowerCase()}
          address={pick(pick_up, keys(DEFAULT_LOCATION_DATA))}
          onAddressChanged={onChangePickUpAddress}
          required
        />
      }
      data-cy="pick-up-form"
    />
  );
};

export default PickUpForm;
