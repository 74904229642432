import FormControls from "library/Molecules/FormControls";
import { compact } from "lodash";
import useNewOrderForm from "pages/NewOrder/hooks/useNewOrderForm";
import { PropTypes } from "prop-types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { retrieveContact } from "store/features/contact.store";
import { LIBRARY_STORAGE_LIMIT, NEW_ORDER_FORM_STEPS } from "../../../../constants";

const NewOrderSubmitButtons = ({ onReset, onSubmit, onSubmitAndSave, resetBtnOptions, submitBtnOptions }) => {
  const { t } = useTranslation();

  const contact = useSelector(retrieveContact);

  const [, { step }] = useNewOrderForm();

  const resetBtnLabel = useMemo(() => {
    return step === NEW_ORDER_FORM_STEPS.SET_DELIVERY ? "common.buttons.resetBtn" : "common.buttons.cancelBtn";
  }, [step]);

  const submitBtnLabel = useMemo(() => {
    switch (step) {
      case NEW_ORDER_FORM_STEPS.SET_DELIVERY:
        return "newOrder.forms.setDeliveryForm.calculateBtn";
      case NEW_ORDER_FORM_STEPS.CALCULATE:
        return "newOrder.forms.calculatePriceForm.continueBtn";
      default:
        return "newOrder.forms.bookingForm.sendBookingBtn";
    }
  }, [step]);

  const isStorageLimitExceeded = useMemo(() => {
    return contact.library?.orders >= LIBRARY_STORAGE_LIMIT;
  }, [contact.library?.orders]);

  return (
    <FormControls
      className="mt-5"
      resetBtnOptions={{
        id: "reset-new-order-form-button",
        label: t(resetBtnLabel),
        onClick: onReset,
        ...resetBtnOptions,
      }}
      submitBtnsOptions={compact([
        step === NEW_ORDER_FORM_STEPS.BOOKING && {
          id: "submit-and-save-new-order-step-button",
          label: t("newOrder.forms.bookingForm.sendAndSaveBookingBtn"),
          onClick: onSubmitAndSave,
          disabled: isStorageLimitExceeded,
        },
        {
          id: "submit-new-order-step-button",
          label: t(submitBtnLabel),
          onClick: onSubmit,
          ...submitBtnOptions,
        },
      ])}
    />
  );
};

NewOrderSubmitButtons.defaultProps = {
  onSubmitAndSave: () => {},
  submitBtnOptions: {},
  resetBtnOptions: {},
};

NewOrderSubmitButtons.propTypes = {
  onReset: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSubmitAndSave: PropTypes.func,
  resetBtnOptions: PropTypes.shape({
    disabled: PropTypes.bool,
    loading: PropTypes.func,
  }),
  submitBtnOptions: PropTypes.shape({
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
  }),
};

export default NewOrderSubmitButtons;
