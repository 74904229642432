import useCookie from "hooks/useCookie";
import useDeviceType from "hooks/useDeviceType";
import { isNull, isUndefined } from "lodash";
import { useMemo } from "react";

const withDefaultDrawerPosition = (Component) => (props) => {
  const { isDesktop } = useDeviceType();
  const [getCookie] = useCookie();

  const defaultDrawerPosition = useMemo(() => {
    if (!isDesktop) return false;
    const drawerPosition = getCookie("drawerPosition");
    if (isNull(drawerPosition) || drawerPosition) return true;
    return false;
  }, [isDesktop, getCookie]);

  return <Component defaultPosition={defaultDrawerPosition} {...props} />;
};

export default withDefaultDrawerPosition;
