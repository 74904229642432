import { cx } from "@emotion/css";
import useDeviceType from "hooks/useDeviceType";
import PrimaryButton from "library/Atoms/Button/PrimaryButton";
import MiscDetailsLayout from "library/Layouts/DetailsLayout/MiscDetailsLayout/MiscDetailsLayout";
import { isEmpty, size } from "lodash";
import useInvoices from "pages/Invoices/hooks/useInvoices";
import { Trans, useTranslation } from "react-i18next";
import { formatFullDate } from "utility/date-fns";
import { convertTimestampToDate, formatPrice } from "utility/helpers/general";
import InvoiceDetailsLayout from "../../layouts/InvoiceDetailsLayout/InvoiceDetailsLayout";
import InvoiceBriefInfo from "../InvoiceList/InvoiceItem/InvoiceBriefInfo/InvoiceBriefInfo";
import styles from "./InvoiceDetails.styles";
import InvoiceDownloadButton from "./InvoiceDownloadButton/InvoiceDownloadButton";
import InvoiceLine from "./InvoiceLine/InvoiceLine";
import InvoiceStatusBanner from "./InvoiceStatusBanner/InvoiceStatusBanner";

const InvoiceDetails = () => {
  const { t } = useTranslation();
  const { isMobile } = useDeviceType();

  const [{ selectedInvoice }, { onDeselectInvoice }] = useInvoices();

  const { id, created_at, updated_at, due_at, contact, lines, price } = selectedInvoice;

  if (isEmpty(selectedInvoice)) return null;
  return (
    <InvoiceDetailsLayout
      statusBarSection={<InvoiceStatusBanner />}
      briefInfoSection={
        <InvoiceBriefInfo id={id} createdAt={created_at} dueAt={due_at} lines={size(lines)} price={price} />
      }
      extraInfoSection={
        <>
          <MiscDetailsLayout
            className="col-sm-4 col-6 my-3"
            titleSection={t("utils.timestamps.createdAt")}
            contentSection={formatFullDate(convertTimestampToDate(created_at))}
          />

          <MiscDetailsLayout
            className="col-sm-4 col-6 my-3"
            titleSection={t("utils.timestamps.updatedAt")}
            contentSection={formatFullDate(convertTimestampToDate(updated_at))}
          />

          <MiscDetailsLayout
            className="col-sm-4 col-6 my-3"
            titleSection={t("utils.timestamps.dueAt")}
            contentSection={formatFullDate(convertTimestampToDate(due_at))}
          />

          {contact.name && (
            <MiscDetailsLayout
              className="col-sm-4 col-6 my-3"
              titleSection={t("invoices.invoice.customerName")}
              contentSection={contact.name}
            />
          )}

          {contact.email && (
            <MiscDetailsLayout className="col-sm-4 col-6 my-3" titleSection="Email" contentSection={contact.email} />
          )}

          {contact.invoiceEmail && (
            <MiscDetailsLayout
              className="col-sm-4 col-6 my-3"
              titleSection="Invoice email"
              contentSection={contact.invoiceEmail}
            />
          )}
        </>
      }
      linesSection={lines?.map((line, index) => (
        <InvoiceLine line={{ ...line, id: index + 1, currency: price.currency }} />
      ))}
      priceSection={
        <>
          <div className="text-right text-fs-14 text-truncate">
            <span data-cy={`invoice-${id}-total-vat`}>
              {t("utils.pricing.totalVat")}: {formatPrice(price.tax, price.currency)}
            </span>
          </div>

          <div className="text-right text-fs-14 text-fw-semibold text-truncate">
            <span className="pr-2">{t("utils.pricing.totalPrice")}:</span>

            <Trans
              i18nKey="utils.pricing.inclVAT"
              tOptions={{ price: formatPrice(price.total, price.currency) }}
              components={{ span: <span data-cy={`invoice-${id}-price`} /> }}
              defaults="<span>{{price}}</span> inc. VAT"
            />
          </div>
        </>
      }
      controlsSection={
        <>
          {!isMobile && <InvoiceDownloadButton className={cx(styles.controlBtn(false), "w-100")} />}

          <PrimaryButton
            id="back-to-invoices-btn"
            className={cx(styles.controlBtn(isMobile), "w-100 mt-4")}
            contentClassName="text-truncate text-fs-12"
            label={t("common.buttons.backToBtn", { context: "invoices" })}
            onClick={onDeselectInvoice}
          />
        </>
      }
    />
  );
};

export default InvoiceDetails;
