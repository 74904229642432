import { cx } from "@emotion/css";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Modal } from "@mui/material";
import useDeviceType from "hooks/useDeviceType";
import IconButton from "library/Atoms/Button/IconButton";
import PropTypes from "prop-types";
import styles from "./styles";

const DefaultModal = ({ id, className, modalClassName, title, onClose, children, size, closable }) => {
  const { isSmallMobile } = useDeviceType();

  return (
    <Modal
      id={id}
      open
      className={cx(className, "d-flex justify-content-center align-items-center text-color-secondary text-fs-12")}
      onBackdropClick={closable ? onClose : () => {}}
      componentsProps={{ backdrop: { "data-cy": `${id}-backdrop` } }}
    >
      <div
        className={cx(
          styles.modal(isSmallMobile, size),
          modalClassName,
          "position-relative px-md-5 px-4 pb-md-5 pb-4 bg-color-white border-rounded"
        )}
      >
        {closable && (
          <IconButton id="modal-close-btn" className={cx(styles.closeIcon, "position-absolute")} onClick={onClose}>
            <CloseRoundedIcon fontSize="small" />
          </IconButton>
        )}

        <div className="text-center text-fs-18 text-color-grey900 text-fw-bold mb-4">{title}</div>

        {children}
      </div>
    </Modal>
  );
};

DefaultModal.defaultProps = {
  id: null,
  className: "",
  modalClassName: "",
  title: null,
  size: "small",
  closable: true,
};

DefaultModal.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  modalClassName: PropTypes.string,
  title: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(["small", "big"]),
  closable: PropTypes.bool,
};

export default DefaultModal;
