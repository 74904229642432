import { cx } from "@emotion/css";
import { ButtonBase } from "@mui/material";
import BoxLayout from "library/Layouts/BoxLayout/BoxLayout";
import StatusIndicator from "library/Molecules/StatusViews/StatusIndicator";
import { size } from "lodash";
import useInvoices from "pages/Invoices/hooks/useInvoices";
import { getInvoiceStatusColor, getInvoiceStatusIcon } from "pages/Invoices/utils/helper";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import InvoiceType from "types/models/Invoice";
import { prettifyStatusLabel } from "utility/helpers/general";
import commonStyles from "utility/ui/common";
import InvoiceBriefInfo from "./InvoiceBriefInfo/InvoiceBriefInfo";

const InvoiceItem = ({ invoice: { id, created_at, due_at, price, lines, status } }) => {
  const { t } = useTranslation();

  const [{ selectedInvoice }, { onSelectInvoice }] = useInvoices();

  const itemRef = useRef(null);

  const isSelected = useMemo(() => selectedInvoice?.id === id, [selectedInvoice?.id, id]);

  const handleSelectInvoice = useCallback(() => onSelectInvoice(id), [onSelectInvoice, id]);

  useEffect(() => {
    if (!itemRef.current || !isSelected) return;
    itemRef.current.scrollIntoView({ block: "center", behavior: "smooth" });
  }, [itemRef, isSelected]);

  return (
    <BoxLayout
      className="my-3"
      componentClassName={cx(
        commonStyles.listItemContainer,
        "d-flex flex-wrap align-items-center justify-content-between gap-3 col-12 py-4 px-3 overflow-hidden"
      )}
      Component={(props) => <ButtonBase {...props} ref={itemRef} onClick={handleSelectInvoice} />}
      selected={isSelected}
      data-cy={`invoice-item-${id}`}
    >
      <InvoiceBriefInfo id={id} createdAt={created_at} dueAt={due_at} lines={size(lines)} price={price} />

      <StatusIndicator
        status={status}
        label={t([`utils.invoiceStatuses.${status}`, prettifyStatusLabel(status)])}
        renderColor={getInvoiceStatusColor}
        renderIcon={getInvoiceStatusIcon}
        data-cy={`invoice-item-chip-${id}-${status}`}
      />
    </BoxLayout>
  );
};

InvoiceItem.defaultProps = {
  invoice: {},
};

InvoiceItem.propTypes = {
  invoice: InvoiceType,
};

export default InvoiceItem;
