import { isWeekend } from "date-fns";
import { PropTypes } from "prop-types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { isPeakTime } from "utility/helpers/general";
import MessageLayout from "../../../layouts/MessageLayout/MessageLayout";

const IncreasedPriceMessage = ({ startDate, isExpress, withoutWeekend }) => {
  const { t } = useTranslation();

  const weekendFlag = useMemo(() => !withoutWeekend && isWeekend(startDate), [withoutWeekend, startDate]);
  const peakTimeFlag = useMemo(() => isPeakTime(startDate), [startDate]);

  const translationKey = useMemo(() => {
    let key = "newOrder.estimation.increasedPriceMessage";

    if (isExpress) {
      key += ".express";
      if (weekendFlag) key += "_weekend";
      else if (peakTimeFlag) key += "_peak";
      return key;
    }

    if (weekendFlag) key += ".weekend";
    else if (peakTimeFlag) key += ".peak";

    return key;
  }, [startDate, isExpress, weekendFlag, peakTimeFlag]);

  if (!isExpress && !weekendFlag && !peakTimeFlag) return null;
  return (
    <MessageLayout
      id="increased-price-message"
      className="col-12 mt-3 px-0"
      labelSection={t(translationKey)}
      color="warning"
      data-cy={`${isExpress ? "express-" : ""}${weekendFlag ? "weekend-" : ""}${peakTimeFlag ? "peak-" : ""}flag`}
    />
  );
};

IncreasedPriceMessage.defaultProps = {
  withoutWeekend: false,
};

IncreasedPriceMessage.propTypes = {
  startDate: PropTypes.instanceOf(Date).isRequired,
  isExpress: PropTypes.bool.isRequired,
  withoutWeekend: PropTypes.bool,
};

export default IncreasedPriceMessage;
