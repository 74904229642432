import { FormikProvider, useFormik } from "formik";
import useDidUpdate from "hooks/useDidUpdate";
import PrimaryButton from "library/Atoms/Button/PrimaryButton";
import PageHeaderLayout from "library/Layouts/PageHeaderLayout";
import { isEmpty } from "lodash";
import useNewOrderForm from "pages/NewOrder/hooks/useNewOrderForm";
import SetDeliveryFormLayout from "pages/NewOrder/layouts/FormLayout/SetDeliveryFormLayout/SetDeliveryFormLayout";
import { generateNewOrder } from "pages/NewOrder/utils/helper";
import PropTypes from "prop-types";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { retrieveContact } from "store/features/contact.store";
import NewOrderSubmitButtons from "../NewOrderSubmitButtons/NewOrderSubmitButtons";
import DateForm from "./DateForm/DateForm";
import DropOffForm from "./DropOffForm/DropOffForm";
import PickUpForm from "./PickUpForm/PickUpForm";
import SetDeliveryFormValidation from "./SetDeliveryForm.validation";

const SetDeliveryForm = ({ onReset, onImport }) => {
  const { t } = useTranslation();

  const contact = useSelector(retrieveContact);

  const [{ order }, { dirty: isNewOrderFormDirty, setDirty: setNewOrderFormDirty }, { onCalculatePrice }] =
    useNewOrderForm();

  const newOrder = useMemo(() => generateNewOrder(contact), [contact]);

  const setDeliveryForm = useFormik({
    initialValues: isEmpty(order) ? newOrder : { ...newOrder, ...order },
    onSubmit: async (values, { setSubmitting }) => {
      await onCalculatePrice(values);
      setSubmitting(false);
    },
    enableReinitialize: true,
    validationSchema: SetDeliveryFormValidation,
  });
  const {
    values: { startDate, pick_up, drops },
    dirty: isFormDirty,
    isSubmitting,
    submitForm,
    resetForm,
    isValid,
  } = setDeliveryForm;

  const isFormEmpty = useMemo(() => isEmpty(order) && !isFormDirty, [order, isFormDirty]);
  const isFormValid = useMemo(() => startDate && isValid, [isValid, startDate]);

  useDidUpdate(
    (prev, curr) => {
      if (prev.isNewOrderFormDirty && !curr.isNewOrderFormDirty) resetForm();
    },
    { isNewOrderFormDirty }
  );

  useEffect(() => {
    setNewOrderFormDirty(!isFormEmpty);
  }, [isFormEmpty, setNewOrderFormDirty]);

  return (
    <FormikProvider value={setDeliveryForm}>
      <SetDeliveryFormLayout
        pageHeaderSection={
          <PageHeaderLayout
            className="pt-3"
            contentClassName="flex-column"
            title={t("newOrder.forms.setDeliveryForm.formTitle")}
            subtitle={t("newOrder.forms.setDeliveryForm.formDescription")}
            headerControls={
              <PrimaryButton
                id="import-order-button"
                className="w-100"
                label={t("newOrder.forms.setDeliveryForm.importBtn")}
                onClick={onImport}
              />
            }
            data-cy="new-order-header"
          />
        }
        dateFormSection={<DateForm />}
        pickUpFormSection={<PickUpForm />}
        dropOffFormsSection={
          pick_up.location && drops.map(({ reference }) => <DropOffForm key={reference} reference={reference} />)
        }
        controlsSection={
          <NewOrderSubmitButtons
            onSubmit={submitForm}
            onReset={onReset}
            submitBtnOptions={{ disabled: !isFormValid, loading: isSubmitting }}
            resetBtnOptions={{ disabled: isFormEmpty }}
          />
        }
      />
    </FormikProvider>
  );
};

SetDeliveryForm.propTypes = {
  onImport: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
};

export default SetDeliveryForm;
