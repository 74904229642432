import Accordion from "library/Atoms/Accordion";
import LoadGoogleMap from "library/GoogleMapComponents/LoadGoogleMap";
import useNewOrderForm from "pages/NewOrder/hooks/useNewOrderForm";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createMarkerPosition } from "utility/helpers/markers";
import NewOrderDetails from "../NewOrderDetails/NewOrderDetails";
import NewOrderSubmitButtons from "../NewOrderSubmitButtons/NewOrderSubmitButtons";
import styles from "./CalculatePriceForm.styles";
import EstimatedDetails from "./EstimatedDetails/EstimatedDetails";
import NewOrderDirectionMap from "./NewOrderDirectionMap/NewOrderDirectionMap";

const CalculatePriceForm = ({ onReset }) => {
  const { t } = useTranslation();

  const [
    {
      price,
      order: { pick_up, drops },
    },
    { handleSetBookingStep },
  ] = useNewOrderForm();

  const pickUpMarker = useMemo(() => {
    const { geolocation, location, postcode } = pick_up;
    const position = createMarkerPosition(geolocation);
    return position ? { position, location: `${location} | ${postcode}` } : null;
  }, [pick_up]);

  const dropMarkers = useMemo(() => {
    return drops.reduce((prev, { location, geolocation, postcode }) => {
      const position = createMarkerPosition(geolocation);
      return position ? [...prev, { position, location: `${location} | ${postcode}` }] : prev;
    }, []);
  }, [drops]);

  const mapBounds = useMemo(() => {
    return [pickUpMarker, ...dropMarkers].map(({ position }) => position);
  }, [pickUpMarker, dropMarkers]);

  return (
    <>
      <div className="d-flex flex-column align-items-start col-12 px-0">
        <div className="col-12 px-0 d-flex flex-column flex-md-row mt-md-5 justify-content-between align-items-start">
          <EstimatedDetails {...price} />

          <NewOrderDetails />
        </div>

        <div className="map col-12 px-0 mt-3 mb-4">
          <Accordion
            id="new-order-map"
            className={styles.collapse}
            label={t("newOrder.forms.calculatePriceForm.shopMapBtn")}
            asyncMount
          >
            <LoadGoogleMap>
              <NewOrderDirectionMap
                className={styles.map}
                bounds={mapBounds}
                pickUpMarker={pickUpMarker}
                dropMarkers={dropMarkers}
              />
            </LoadGoogleMap>
          </Accordion>
        </div>
      </div>

      <NewOrderSubmitButtons onSubmit={handleSetBookingStep} onReset={onReset} />
    </>
  );
};

CalculatePriceForm.propTypes = {
  onReset: PropTypes.func.isRequired,
};

export default CalculatePriceForm;
