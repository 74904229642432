import { getAuth } from "@firebase/auth";

export const getUserUID = () => {
  const auth = getAuth();
  const user = JSON.parse(localStorage.getItem("authUser"));

  return auth?.currentUser?.uid || user.uid;
};

export const checkIsAdmin = async () => {
  const auth = getAuth();
  const user = JSON.parse(localStorage.getItem("authUser"));

  if (user) return Boolean(user?.claims?.pedivanAdmin);
  const res = await auth.currentUser?.getIdTokenResult();
  return Boolean(res?.claims?.pedivanAdmin);
};
